import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
	Button,
	Grid,
	Paper,
	Typography,
	Dialog,
	DialogContent,
	IconButton,
	CircularProgress,
	Checkbox,
	FormControlLabel,
	TextField,
	DialogActions,
	Box,
	Snackbar,
	Badge
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import './ContextMenu.css';
//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';
import blue from '@material-ui/core/colors/blue';
import {
	getListing,
	getPdfOffmarket,
	checkUserHasPermissionId,
	getPdfOfCustomListing,
	getListingRank,
	getSavedRoute,
	getGenerateYourOwnPdf,
	getEstimations,
	getClosestPointsOfInterest,
	getUserBalance,
	updateUserBalance,
	getOwnerDetailsInfomation,
	sendToFollowUpBoss,
	getAiLetter,
	checkRenovated,
	createNewOrderConnection,
	checkListingHasOrderConnection,
	getChipsForListing
} from '../../../api/serverRequests';
import { useUserState } from '../../../hook/customerHook';
import { BaseLayout, Loading, Map, DetailsPageListTitle, DetailsPageTabs } from '../..';
import { KeyboardArrowLeft, KeyboardArrowRight, GetApp, AssignmentInd } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { PERMS } from '../../../helper/perms';
import { isLetter } from '../../../helper/parser';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';

const NotFoundError = loadable(() => import('../..'), {
	resolveComponent: (components) => components.NotFoundError
});

const useStyles = makeStyles((theme) => ({
	listTitleAndMapContainer: {
		padding: '2.5rem',
		[theme.breakpoints.down('xs')]: {
			padding: '2rem'
		}
	},
	tabsContainer: {
		padding: '2.5rem',
		[theme.breakpoints.down('xs')]: {
			padding: '1.2rem'
		}
	},
	backButton: {
		paddingLeft: 7,
		paddingRight: 7,
		display: 'flex',
		marginBottom: '1.75rem',
		textTransform: 'none'
	},
	forwardButton: {
		marginLeft: 'auto',
		'& .MuiBadge-anchorOriginTopRightRectangle': {
			transform: 'scale(0.5) translate(50%, -50%)'
		}
	},
	listingNav: {
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	mapContainer: {
		margin: '0',
		[theme.breakpoints.down('sm')]: {
			paddingTop: 40
		}
	},
	mainInfo: {
		marginTop: '2.75rem'
	},
	otherListing: {
		padding: theme.spacing(3, 5),
		width: '100%',
		height: '100%'
	},
	customDialog: {
		maxWidth: '500px',
		width: '100%'
	},
	dialogContent: {
		height: '165px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-end',
		marginBottom: '14px',
		justifyContent: 'center',
		alignItems: 'center'
	},
	spinner: {
		margin: 'auto'
	},
	icons: {
		color: theme.palette.grey.KN_grey
	},
	button: {
		flex: 1,
		margin: 10
	},
	bottomButtons: {
		padding: '0.8rem 1.4rem',
		textTransform: 'none',

		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.grey['KN_grey'],
		borderRadius: '5px',
		borderWidth: '1.5px',
		borderColor: theme.palette.grey['KN_grey']
	},
	stepper: {
		background: '#fafafa'
	},
	stepCircle: {
		borderRadius: '50%',
		width: 24,
		height: 24
	},
	activeStepIcon: {
		color: '#fff',
		background: theme.palette.primary.main,
		borderRadius: '50%'
	},
	activeLine: {
		backgroundColor: theme.palette.primary.main,
		height: 2,
		flexGrow: 1,
		margin: '0 4px'
	},
	serviceBox: {
		display: 'flex',
		paddingLeft: theme.spacing(1),
		'& svg': {
			marginRight: theme.spacing(1)
		},
		'& .MuiTypography-root': {
			color: 'black'
		}
	},
	creditServiceDialogContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-end',
		marginBottom: '14px'
	},
	numberOfCredits: {
		textAlign: 'center',
		margin: theme.spacing(1, 0, 2)
	},
	creditsLeft: {
		fontWeight: 500,
		fontSize: '18px',
		color: 'black'
	},
	cancelButton: {
		textDecoration: 'underline'
	},
	loadingSpinner: {
		display: 'flex',
		width: '100%',
		height: '100%',
		justifyContent: 'space-around',
		alignItems: 'center'
	}
}));

// styling for dialogTitle
const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		flex: 1,
		color: blue[900],
		margin: 10,
		justifyContent: 'center',
		alignSelf: 'center'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});

const DialogTitle = withStyles(styles)(({ children, classes, onClose, ...other }) => {
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const StartRoutes = loadable(() => import('../StartRoutes'));

export default function DetailsPage() {
	const classes = useStyles();
	const location = useLocation();
	const history = useHistory();
	const [userState, dispatchUser] = useUserState();

	// storing listing data
	const [listing, setListing] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [MLNum, setMLNum] = useState('');
	const [homeId, setHomeId] = useState(0);
	const [currentPropertyIndex, setCurrentPropertyIndex] = useState(null); // storing the index of current listing (for next/previous buttons)
	const [totalNumProperties, setTotalNumProperties] = useState(null); // total number of properties in offmarket search result

	// below state determines if user has used previous/next button to go to a new property
	// it is used to force a rerender of child components, to fetch new data in tabs components
	const [userNavigatedToNewProperty, setUserNavigatedToNewProperty] = useState(false);

	// BELOW STATES ARE TO DO WITH PDF GENERATION
	const [popupOwner, setPopupOwner] = useState(false);
	const [popupOffmarket, setPopupOffmarket] = useState(false);
	const [popupAiLetter, setPopupAiLetter] = useState(false);

	const [popupLoading, setPopupLoading] = useState(false);
	const [pdfOwnerUrl, setPdfOwnerUrl] = useState('');
	const [pdfOffmarketUrl, setPdfOffmarketUrl] = useState('');
	const [offmarketError, setOffmarketError] = useState(false);
	const [ownerLetterError, setOwnerLetterError] = useState(false);
	const [checkedLetterheadCustomPDF, setCheckedLetterheadCustomPDF] = useState(
		userState.checkedLetterheadCustomPDF
	);
	const [checkedLetterheadAILetter, setCheckedLetterheadAILetter] = useState(
		userState.checkedLetterheadAILetter
	);
	const [checkedUsernameCustomPDF, setCheckedUsernameCustomPDF] = useState(
		userState.checkedUsernameCustomPDF
	);
	const [checkedUsernameAILetter, setCheckedUsernameAILetter] = useState(
		userState.checkedUsernameAILetter
	);
	const [agentLetterVisible, setAgentLetterVisible] = useState(false);
	const [aiLetterVisible, setAiLetterVisible] = useState(false);
	const [aiLetterError, setAiLetterError] = useState(false);

	const [popupDoorKnock, setPopupDoorKnock] = useState(false);
	const [checkedDoorKnock, setCheckedDoorKnock] = useState(false);
	const [checkedSendLetter, setCheckedSendLetter] = useState(false);
	const [existingOrderConnection, setExistingOrderConnection] = useState([]);

	// check listing history for bad reputation
	const [checkListingHistory, setCheckListingHistory] = useState([]);

	// permissions for knocking status, comments, and tags (that are all currently 'view')
	const [knockingStatusVisible, setKnockingStatusVisible] = useState(false);
	const [viewCommentsVisible, setViewCommentsVisible] = useState(false);
	const [tagsVisible, setTagsVisible] = useState(false);
	// permission for assigning tasks
	const [editAssignedTaskVisible, setEditAssignedTaskVisible] = useState(false);
	// permission for call recordings
	const [viewCallRecordingsVisible, setViewCallRecordingsVisible] = useState(false);
	// permission for adding comments
	const [addCommentsVisible, setAddCommentsVisible] = useState(false);
	// permission for viewing top agents tab
	const [viewTopAgentsVisible, setViewTopAgentsVisible] = useState(false);
	// permission for assign task only for myself
	const [assignMyself, setAssignMyself] = useState(false);
	// permission for view edit history in custom tab
	const [viewEditHistoryVisible, setviewEditHistoryVisible] = useState(false);
	// permission for generating your own custom template
	const [viewGenerateYourOwnPdf, setViewGenerateYourOwnPdf] = useState(false);
	// pop up for custom template
	const [customTemplatePopup, setCustomTemplatePopup] = useState(false);
	const [pdfCustomOwnerUrl, setPdfCustomOwnerUrl] = useState('');
	// permission for viewing CRM
	const [viewCRM, setViewCRM] = useState(false);

	// permission for viewing owner name
	const [viewOwnerName, setViewOwnerName] = useState(false);
	// permission for viewing owner phone number
	const [viewOwnerPhone, setViewOwnerPhone] = useState(false);

	// pop up and textareas for generating your own custom template
	const [generateCustomPopup, setGenerateCustomPopup] = useState(false);
	const [intro1, setIntro1] = useState('');
	const [home1, setHome1] = useState('');
	const [neighbourhood1, setNeighbourhood1] = useState('');
	const [closing1, setClosing1] = useState('');

	const [intro2, setIntro2] = useState('');
	const [home2, setHome2] = useState('');
	const [neighbourhood2, setNeighbourhood2] = useState('');
	const [closing2, setClosing2] = useState('');

	const [intro3, setIntro3] = useState('');
	const [home3, setHome3] = useState('');
	const [neighbourhood3, setNeighbourhood3] = useState('');
	const [closing3, setClosing3] = useState('');

	// permission ids
	const KNOCKING_STATUS_PERM_ID = PERMS.KNOCKING_STATUS;
	const VIEW_COMMENTS_PERM_ID = PERMS.VIEW_COMMENTS;
	const TAGS_PERM_ID = PERMS.TAGS;
	const EDIT_ASSIGN_TASKS_PERM_ID = PERMS.EDIT_ASSIGN_TASKS;
	const ADD_COMMENTS_PERM_ID = PERMS.ADD_COMMENTS;
	const VIEW_RECORDINGS_PERM_ID = PERMS.VIEW_RECORDINGS;
	const VIEW_TOP_AGENTS_PERM_ID = PERMS.VIEW_TOP_AGENTS;

	const AGENT_LETTER_PERM_ID = PERMS.AGENT_LETTER;
	const CUSTOM_OWNER_LETTER_PERM_ID = PERMS.CUSTOM_OWNER_LETTER;
	const ASSIGN_TASK_MYSELF = PERMS.ASSIGN_TASK_MYSELF;
	const VIEW_EDIT_HISTORY_PERM_ID = PERMS.EDIT_ASSIGN_TASKS;
	const VIEW_GENERATE_YOUR_PDF_PERM_ID = PERMS.VIEW_GENERATE_YOUR_PDF;
	const VIEW_CRM_PERM_ID = PERMS.VIEW_CRM_ACCESS;
	const VIEW_OWNER_NAME = PERMS.VIEW_OWNER_NAME;
	const VIEW_OWNER_PHONE = PERMS.VIEW_OWNER_PHONE;
	const AI_LETTER_PERM_ID = PERMS.AI_LETTER;

	const [customOwnerLetterVisible, setCustomOwnerLetterVisible] = useState(false);

	const [shouldRefetch, setShouldRefetch] = useState(false); // after the user clicks save we update this state so the useEffect runs again

	const [assignedInfo, setAssignedInfo] = useState({}); // object that holds active agents

	const [permissionChecked, setPermissionChecked] = useState(false);

	const [isLoadingSecondary, setIsLoadingSecondary] = useState(true); // for add note and tags menu items

	const [token, setToken] = useState(0);
	const [ownerDetailsInformation, setOwnerDetailsInformation] = useState([]);
	const [ownerInfoLoading, setOwnerInfoLoading] = useState(true);

	const [sendToCRMLoading, setSendToCRMLoading] = useState(false);

	const [chipsForFollowUp, setChipsForFollowUp] = useState([]);

	const [isUsingHomeId, setIsUsingHomeId] = useState(true);

	const [chipData, setChipData] = useState('');

	useEffect(() => {
		if (location.pathname.split('/')[2] !== 'start-routes') {
			dispatchUser({
				routingList: null
			});
			dispatchUser({
				currentRouteId: null
			});
			userState.routingList = null;
			userState.currentRouteId = null;
		} else {
			setIsLoading(true);

			if (location.pathname.split('/')[4] !== '0') {
				getSavedRoute(location.pathname.split('/')[4])
					.then((res) => {
						console.log('what is the response for getSavedRoutes?', res.data.routes);
						const updatedRoutingList = {};
						res.data.routes.forEach((route) => {
							updatedRoutingList[route.Id] = route;
						});
						dispatchUser({
							currentRouteId: updatedRoutingList[location.pathname.split('/')[4]].Id
						});
						dispatchUser({ routingList: updatedRoutingList });

						setIsLoading(false);
					})
					.catch((err) => {
						console.log(err);
						setIsLoading(false);
					});
			}
			setIsLoading(false);
		}

		getUserBalance(userState.user.user_id)
			.then((res) => {
				if (res.data.success) {
					setToken(res.data.updated_balance / 100 / 25);
				}
			})
			.catch((err) => {
				console.error(err.message);
			});
	}, []);

	useEffect(() => {
		let unmounted = false;
		const parameter = location.pathname.split('/')[3];

		// only MLNum starts with a letter, so if it's not a letter, means we're using homeId
		let isUsingMLNum = isLetter(parameter[0]);
		if (isUsingMLNum) {
			setIsUsingHomeId(false);
			setMLNum(location.pathname.split('/')[3]);
		} else {
			setIsUsingHomeId(true);
		}

		// api call to fetch the listing by MLNum
		const fetchListingData = async () => {
			getListing(location.pathname.split('/')[3])
				.then((res) => {
					if (res.status === 200) {
						let property = res.data.listing;
						setListing(property);
						setHomeId(property.HomeId);
					}
					if (!unmounted) setIsLoading(false);
				})
				.catch((err) => {
					if (err.response && err.response.status === 401) {
						dispatchUser({
							isLoggedIn: false
						});
					} else if (!unmounted) setIsLoading(false);
					if (err.message) {
						console.error(err.message);
					}
				});
		};

		// check if we have loaded the details page
		// if isLoading === false we must fetch the listing
		if (isLoading) {
			fetchListingData();
			return () => {
				unmounted = true;
			};
		}
		// if isLoading === true, we have fetched the listing and
		// can use the property data stored in userState.offMarketSearchResults
		// to allow users to move to the previous or next properties via the previous & next buttons
		else {
			let result = '';
			let resultMLNum = '';
			setChipData({});

			if (userState.routingList) {
				result =
					userState.routingList[userState.currentRouteId].listings[currentPropertyIndex];
				resultMLNum =
					userState.routingList[userState.currentRouteId].listings[currentPropertyIndex]
						.MLNum;
			} else {
				dispatchUser({
					routingList: null
				});
				dispatchUser({
					currentRouteId: null
				});
				result = userState.offMarketSearchResults[currentPropertyIndex];
				resultMLNum = userState.offMarketSearchResults[currentPropertyIndex].MLNum;
			}

			setListing(result);
			setHomeId(result.HomeId);
			setMLNum(resultMLNum);
			const newPath = userState.routingList
				? `/user/start-routes/${resultMLNum}/${userState.currentRouteId}`
				: `/user/listing/${resultMLNum}`;

			if (history.location.pathname !== newPath) {
				history.push(newPath);
			}
			// in case where the user is spamming the next/previous buttons,
			// we do not want to load the data, of properties that are being skipped through.
			// So a timeout is set to wait for X amount of time after the user clicks a button,
			// to then change the state of userNavigatedToNewProperty which forces a rerender of child components.
			const delayDebounceButtons = setTimeout(() => {
				setUserNavigatedToNewProperty(!userNavigatedToNewProperty);
			}, 500);

			return () => {
				clearTimeout(delayDebounceButtons);
			};
		}
	}, [currentPropertyIndex]);

	useEffect(() => {
		if (!homeId || homeId === 0) return;
		const fetchListingRankData = async () => {
			getListingRank(homeId)
				.then((res) => {
					if (res.status === 200) {
						let property = res.data.listing;
						setListing((prevState) => ({
							...prevState,
							...property
						}));
					}
				})
				.catch((err) => {
					if (err.response && err.response.status === 401) {
						dispatchUser({
							isLoggedIn: false
						});
					}
					console.error(err.message);
				});
		};

		const fetchEstimations = async () => {
			getEstimations(homeId)
				.then((res) => {
					if (res.status === 200) {
						let estimation = res.data.estimation;
						if (estimation) {
							setListing((prevListing) => ({
								...prevListing,
								estimate_high: estimation.estimate_high,
								estimate_low: estimation.estimate_low,
								estimate_mid: estimation.estimate_mid,
								tax: estimation.tax
							}));
						}
					}
				})
				.catch((err) => {
					if (err.response && err.response.status === 401) {
						dispatchUser({
							isLoggedIn: false
						});
					}
					console.error(err.message);
				});
		};

		const fetchOwnerDetailsInfomation = async () => {
			setOwnerInfoLoading(true);
			getOwnerDetailsInfomation(homeId, MLNum)
				.then((res) => {
					if (res.status === 200) {
						setOwnerDetailsInformation(res.data.owners_details_information);
						setOwnerInfoLoading(false);
					}
				})
				.catch((err) => {
					if (err.response && err.response.status === 401) {
						dispatchUser({
							isLoggedIn: false
						});
						setOwnerInfoLoading(false);
					}
					console.error(err.message);
				});
		};

		const fetchAllChips = async () => {
			try {
				const res = await getChipsForListing(homeId);
				if (res.status === 200) {
					setChipData(res.data.chip_data);
				}
			} catch (err) {
				if (err.response && err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
				}
				console.error(err.message);
			}
		};

		fetchListingRankData();
		fetchEstimations();
		fetchOwnerDetailsInfomation();

		fetchAllChips();
	}, [homeId]);

	useEffect(() => {
		const getIndexByMLNum = (array, MLNum) => {
			const obj = array.find((obj) => obj.MLNum === MLNum);
			return obj ? array.indexOf(obj) : null;
		};
		if (userState.routingList) {
			let currIndex = getIndexByMLNum(
				userState.routingList[userState.currentRouteId].listings,
				location.pathname.split('/')[3]
			);
			setCurrentPropertyIndex(currIndex);
			setTotalNumProperties(userState.routingList[userState.currentRouteId].listings.length);
		}
		// check to see if Off Market Search is in Context
		else if (userState.offMarketSearchResults) {
			console.log('userState.offMarketSearchResults');
			dispatchUser({
				routingList: null
			});
			dispatchUser({
				currentRouteId: null
			});
			let currIndex = getIndexByMLNum(
				userState.offMarketSearchResults,
				location.pathname.split('/')[3]
			);
			setCurrentPropertyIndex(currIndex);
			setTotalNumProperties(userState.offMarketSearchResults.length);
		} else {
			dispatchUser({
				routingList: null
			});
			dispatchUser({
				currentRouteId: null
			});
			setCurrentPropertyIndex(null);
		}
	}, [location.pathname, userState.offMarketSearchResults, userState.routingList]);

	useEffect(() => {
		const checkAgentLetterPermission = async () => {
			checkUserHasPermissionId(AGENT_LETTER_PERM_ID, userState.user.user_id)
				.then((res) => {
					if (res.data.success) {
						setAgentLetterVisible(true);
					}
				})
				.catch((err) => {
					if (err.response && err.response.status === 401) {
						dispatchUser({
							isLoggedIn: false
						});
					}
					console.error(err.message);
				});
		};
		const checkAiLetterPermission = async () => {
			checkUserHasPermissionId(AI_LETTER_PERM_ID, userState.user.user_id)
				.then((res) => {
					if (res.data.success) {
						setAiLetterVisible(true);
					}
				})
				.catch((err) => {
					if (err.response && err.response.status === 401) {
						dispatchUser({
							isLoggedIn: false
						});
					}
					console.error(err.message);
				});
		};
		checkAgentLetterPermission();
		checkAiLetterPermission();
	}, []);

	useEffect(() => {
		let unmounted = false;
		const checkUserAssignedTask = async (setState) => {
			if (assignedInfo && assignedInfo.assignedTask === userState.user.user_id) {
				setState(true);
			}
		};

		const checkPermission = async (permissionId, setState, checkForAssigned = true) => {
			try {
				const response = await checkUserHasPermissionId(
					permissionId,
					userState.user.user_id
				);
				if (response.data.success) {
					setState(true);
				}
			} catch (err) {
				if (err.response && err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
				} else if (err.response && err.response.status === 403) {
					if (checkForAssigned) checkUserAssignedTask(setState);
					console.error(err.message);
				} else {
					console.error(err.message);
				}
			}
		};

		Promise.all([
			checkPermission(KNOCKING_STATUS_PERM_ID, setKnockingStatusVisible),
			checkPermission(VIEW_COMMENTS_PERM_ID, setViewCommentsVisible),
			checkPermission(TAGS_PERM_ID, setTagsVisible),
			checkPermission(EDIT_ASSIGN_TASKS_PERM_ID, setEditAssignedTaskVisible, false),
			checkPermission(VIEW_RECORDINGS_PERM_ID, setViewCallRecordingsVisible, false),
			checkPermission(ADD_COMMENTS_PERM_ID, setAddCommentsVisible),
			checkPermission(VIEW_TOP_AGENTS_PERM_ID, setViewTopAgentsVisible, false),
			checkPermission(ASSIGN_TASK_MYSELF, setAssignMyself, false),
			checkPermission(VIEW_EDIT_HISTORY_PERM_ID, setviewEditHistoryVisible, false),
			checkPermission(VIEW_GENERATE_YOUR_PDF_PERM_ID, setViewGenerateYourOwnPdf, false),
			checkPermission(CUSTOM_OWNER_LETTER_PERM_ID, setCustomOwnerLetterVisible),
			checkPermission(VIEW_CRM_PERM_ID, setViewCRM, false),
			checkPermission(VIEW_OWNER_NAME, setViewOwnerName),
			checkPermission(VIEW_OWNER_PHONE, setViewOwnerPhone)
		]);
		return () => {
			unmounted = true;
		};
	}, [assignedInfo]);

	useEffect(() => {
		if (!homeId || homeId === 0) return;
		const fetchExistingOrderConnection = async () => {
			checkListingHasOrderConnection(homeId).then((res) => {
				if (!res.data.isOrderExist) return;
				const orders = res.data.existingOrders;
				for (let order of orders) {
					if (order['ServiceType'] === 'DoorKnock') {
						setCheckedDoorKnock('disabled');
					} else if (order['ServiceType'] === 'SendLetter') {
						setCheckedSendLetter('disabled');
					}
				}
				setExistingOrderConnection(orders);
			});
		};
		fetchExistingOrderConnection();
	}, [homeId]);

	const handlePopupClose = () => {
		setPopupOffmarket(false);
		setCustomTemplatePopup(false);
		setGenerateCustomPopup(false);
		setPopupAiLetter(false);
	};

	const handleOffmarketPdf = async (locationOfProperty) => {
		setPopupLoading(true);
		getPdfOffmarket(location.pathname.split('/')[3], locationOfProperty)
			.then((res) => {
				if (res.status === 200) {
					const downloadUrl = window.URL.createObjectURL(res.data);
					// open pdf file on new tab
					window.open(downloadUrl, '_blank');
					setPdfOffmarketUrl(downloadUrl);
					setPopupLoading(false);
					setPopupOffmarket(false);
				}
			})
			.catch((err) => {
				setPopupLoading(false);
				setOffmarketError(true);
				console.error(err);
				if (err.response && err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
				}
				console.error(err.message);
			});
	};

	const handleCustomPdf = async (generateMethod, letterhead, username) => {
		setPopupLoading(true);
		getPdfOfCustomListing(
			location.pathname.split('/')[3],
			userState.user.name,
			'Generate PDF',
			letterhead,
			username
		)
			.then((res) => {
				if (res.status === 200) {
					const downloadUrl = window.URL.createObjectURL(res.data);
					setPdfCustomOwnerUrl(downloadUrl);
					setPopupLoading(false);
				}
			})
			.catch((err) => {
				setPopupLoading(false);
				setOwnerLetterError(true);
				console.log(err);
				if (err.response && err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
				}
				console.error(err.message);
			});
	};

	const handleAiLetter = async (generateMethod, letterhead, username) => {
		setPopupLoading(true);
		getAiLetter(homeId, userState.user.name, 'Generate PDF', letterhead, username)
			.then((res) => {
				if (res.status === 200) {
					const downloadUrl = window.URL.createObjectURL(res.data);
					setPdfCustomOwnerUrl(downloadUrl);
					setPopupLoading(false);
				}
			})
			.catch((err) => {
				setPopupLoading(false);
				setAiLetterError(true);
				console.log(err);
				if (err.response && err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
				}
				console.error(err.message);
			});
	};

	const handleChangeLetterhead = (event, type) => {
		console.log({ type });
		if (type === 'CustomPDF') {
			setCheckedLetterheadCustomPDF(event.target.checked);
			dispatchUser({ checkedLetterheadCustomPDF: event.target.checked });
		} else if (type === 'AILetter') {
			setCheckedLetterheadAILetter(event.target.checked);
			dispatchUser({ checkedLetterheadAILetter: event.target.checked });
		}
	};

	const handleChangeUsername = (event, type) => {
		if (type === 'CustomPDF') {
			setCheckedUsernameCustomPDF(event.target.checked);
			dispatchUser({ checkedUsernameCustomPDF: event.target.checked });
		} else if (type === 'AILetter') {
			setCheckedUsernameAILetter(event.target.checked);
			dispatchUser({ checkedUsernameAILetter: event.target.checked });
		}
	};

	const resetPermissions = () => {
		setIsLoadingSecondary(true);
		setKnockingStatusVisible(false);
		setViewCommentsVisible(false);
		setTagsVisible(false);
		setEditAssignedTaskVisible(false);
		setViewCallRecordingsVisible(false);
		setAddCommentsVisible(false);
		setViewTopAgentsVisible(false);
		setPdfOwnerUrl('');
		setPdfCustomOwnerUrl('');
		setPdfOffmarketUrl('');
		setOwnerLetterError(false);
		setOffmarketError(false);
		setAssignMyself(false);
		setviewEditHistoryVisible(false);
	};

	const handlePreviousProperty = () => {
		if (currentPropertyIndex > 0) {
			setCurrentPropertyIndex((prevPropertyIndex) => prevPropertyIndex - 1);
			scrollToTop();
			resetPermissions();
		}
	};

	const handlNextProperty = () => {
		if (currentPropertyIndex < totalNumProperties - 1) {
			setCurrentPropertyIndex((prevPropertyIndex) => prevPropertyIndex + 1);
			scrollToTop();
			resetPermissions();
		}
	};

	const scrollToTop = () => {
		const topOfDetailsPage = document.getElementById('property-detail-top');
		if (topOfDetailsPage) {
			topOfDetailsPage.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const [activeStep, setActiveStep] = useState();
	const loadFromLocalStorage = (key) => {
		const data = localStorage.getItem(key);
		return data ? JSON.parse(data) : null;
	};
	useEffect(() => {
		if (userState.routingList) {
			const savedData = loadFromLocalStorage(location.pathname.split('/')[4]);
			if (savedData) {
				setActiveStep(savedData.activeStep);
			} else {
				setActiveStep(0);
			}
		}
	}, [userState.routingList]);

	// generate your own custom pdf function call
	const handleGenerateYourOwnerPdf = async () => {
		setPopupLoading(true);
		getGenerateYourOwnPdf(
			location.pathname.split('/')[3],
			userState.user.name,
			intro1,
			home1,
			neighbourhood1,
			closing1,
			intro2,
			home2,
			neighbourhood2,
			closing2,
			intro3,
			home3,
			neighbourhood3,
			closing3
		)
			.then((res) => {
				if (res.status === 200) {
					const downloadUrl = window.URL.createObjectURL(res.data);
					setPdfOwnerUrl(downloadUrl);
					setPopupLoading(false);
				}
			})
			.catch((err) => {
				setPopupLoading(false);
				setOwnerLetterError(true);
				console.log(err);
				if (err.response && err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
				}
				console.error(err.message);
			});
	};

	const [contextMenuVisible, setContextMenuVisible] = useState(false);

	const handleContextMenu = (e) => {
		e.preventDefault();
		setContextMenuVisible(true);
		const clickX = e.clientX;
		const clickY = e.clientY;
	};

	const handleMenuItemClick = (value) => {
		// setHome(home + value);
		setContextMenuVisible(false);
	};

	const [activeSteps, setActiveSteps] = useState(0);

	const handleStepClick = (step) => {
		setActiveSteps(step);
		// setIntro('');
		// setHome('');
		// setNeighbourhood('');
		// setClosing('');
	};

	const handleTextFieldChange = (step, field, value) => {
		if (step === 0) {
			if (field === 'intro') {
				setIntro1(value);
			} else if (field === 'home') {
				setHome1(value);
			} else if (field === 'neighbourhood') {
				setNeighbourhood1(value);
			} else if (field === 'closing') {
				setClosing1(value);
			}
		} else if (step === 1) {
			if (field === 'intro') {
				setIntro2(value);
			} else if (field === 'home') {
				setHome2(value);
			} else if (field === 'neighbourhood') {
				setNeighbourhood2(value);
			} else if (field === 'closing') {
				setClosing2(value);
			}
		} else if (step === 2) {
			if (field === 'intro') {
				setIntro3(value);
			} else if (field === 'home') {
				setHome3(value);
			} else if (field === 'neighbourhood') {
				setNeighbourhood3(value);
			} else if (field === 'closing') {
				setClosing3(value);
			}
		}
	};
	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted
	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message
	const [selected, setSelected] = useState([]);

	const updateChipsForFollowUp = (newChips) => {
		if (newChips.length > 0) {
			const newLabels = newChips.map((chip) => chip.label);
			const updatedChips = Array.from(new Set([...chipsForFollowUp, ...newLabels]));
			setChipsForFollowUp(updatedChips);
		}
	};
	const onClickSendToFUB = (event) => {
		setSendToCRMLoading(true);
		const data = {
			userId: userState.user.user_id,
			organizationId: userState.user.organization,
			listings: [location.pathname.split('/')[3]],
			chips: chipsForFollowUp
		};
		sendToFollowUpBoss(data)
			.then((res) => {
				if (res.data.success) {
					setSendToCRMLoading(false);
					setNotificationState(true);
					const data = res.data.results;
					const sent = data['sent'];
					const failed = data['failed'];
					if (failed && failed.length <= 0) {
						setSubmitSuccessStatus('success');
						setNotificationMessage(`Successfully sent ${sent.length} lead to the CRM!`);
					} else {
						setSubmitSuccessStatus('error');
						setNotificationMessage(
							`Sent ${sent.length} to the CRM. Failed to send ${failed
								.map((item) => {
									return item;
								})
								.join(', ')}.`
						);
					}
				}
			})
			.catch((err) => {
				if (err.response.status === 400) {
					setSubmitSuccessStatus('error');
					setNotificationState(true);
					setNotificationMessage('Your organization has not set up their API key yet.');
				} else if (err.response.status === 401) {
					setIsLoading(false);
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
				} else if (err.response.status === 406) {
					setSubmitSuccessStatus('error');
					setNotificationState(true);
					setNotificationMessage(
						`No results were found with your selection${
							selected && selected.length > 1 ? 's.' : '.'
						}`
					);
				}
				setIsLoading(false);

				console.log(err.message);
			});
	};

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const crm = queryParams.get('crm');

		if (crm === 'true') {
			setSendToCRMLoading(true);
			const data = {
				userId: userState.user.user_id,
				organizationId: userState.user.organization,
				listings: [location.pathname.split('/')[3]],
				chips: chipsForFollowUp
			};
			sendToFollowUpBoss(data)
				.then((res) => {
					if (res.data.success) {
						setSendToCRMLoading(false);
						setNotificationState(true);
						const data = res.data.results;
						const sent = data['sent'];
						const failed = data['failed'];
						if (failed && failed.length <= 0) {
							setSubmitSuccessStatus('success');
							setNotificationMessage(
								`Successfully sent ${sent.length} lead to the CRM!`
							);
						} else {
							setSubmitSuccessStatus('error');
							setNotificationMessage(
								`Sent ${sent.length} to the CRM. Failed to send ${failed
									.map((item) => item)
									.join(', ')}.`
							);
						}
					}
				})
				.catch((err) => {
					if (err.response.status === 400) {
						setSubmitSuccessStatus('error');
						setNotificationState(true);
						setNotificationMessage(
							'Your organization has not set up their API key yet.'
						);
					} else if (err.response.status === 401) {
						setIsLoading(false);
						setNotificationState(true);
						setNotificationMessage(
							'Your connection is time out. Please log in again to continue'
						);
						dispatchUser({ isLoggedIn: false });
					} else if (err.response.status === 406) {
						setSubmitSuccessStatus('error');
						setNotificationState(true);
						setNotificationMessage(
							`No results were found with your selection${
								selected && selected.length > 1 ? 's.' : '.'
							}`
						);
					}
					setIsLoading(false);
					console.log(err.message);
				});
		}
	}, [location.search]);

	const calcCredits = () => {
		let totalCredits = 0;
		if (checkedDoorKnock === true) {
			totalCredits += 2;
		}
		if (checkedSendLetter === true) {
			totalCredits += 1;
		}
		return totalCredits;
	};

	const handleSubmitOrderConnection = async (e) => {
		e.preventDefault();
		let totalCredits = calcCredits();
		if (totalCredits < 0 || token <= 0 || totalCredits > token) {
			return;
		}
		updateUserBalance(userState.user.user_id, -totalCredits * 25 * 100).then((res) => {
			if (res.data.success) {
				setToken(res.data.updated_balance / 100 / 25);
			}
		});
		let serviceType = [];
		if (checkedDoorKnock) {
			serviceType.push('DoorKnock');
			setCheckedDoorKnock('disabled');
		}
		if (checkedSendLetter) {
			serviceType.push('SendLetter');
			setCheckedSendLetter('disabled');
		}
		createNewOrderConnection({
			userId: userState.user.user_id,
			homeId: homeId,
			serviceType: serviceType
		}).then((res) => {
			if (res.data.success) {
				setPopupDoorKnock(false);
			}
		});
	};

	const handleCancelPopupDoorKnock = () => {
		if (checkedDoorKnock === true) {
			setCheckedDoorKnock(false);
		}
		if (checkedSendLetter === true) {
			setCheckedSendLetter(false);
		}
		setPopupDoorKnock(false);
	};

	return isLoading ? (
		<BaseLayout>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<Loading />
					</Paper>
				</Grid>
			</Grid>
		</BaseLayout>
	) : listing ? (
		<BaseLayout>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			{userState.routingList && <StartRoutes activeStep={activeStep} />}
			<Grid id="property-detail-top" container className={classes.listingNav}>
				<Grid item xs>
					<Button
						variant="text"
						className={classes.backButton}
						onClick={() => {
							history.goBack();
						}}
					>
						<KeyboardArrowLeft className={classes.icons} />
						<Typography variant="h6" component="h6">
							Back
						</Typography>
					</Button>
				</Grid>
				{/* <Grid item>
					<Button
						variant="text"
						className={`${classes.forwardButton} ${classes.backButton}`}
						onClick={() => setPopupDoorKnock(true)}
					>
						<Typography variant="h6" component="h6">
							Order Connection
						</Typography>
						{checkedDoorKnock || checkedSendLetter ? (
							<Badge badgeContent={'Ordered'} color="primary">
								<HomeIcon className={classes.icons} />
							</Badge>
						) : (
							<HomeIcon className={classes.icons} />
						)}
					</Button>
				</Grid> */}
				{aiLetterVisible && (
					<Grid item>
						<Button
							variant="text"
							className={`${classes.forwardButton} ${classes.backButton}`}
							onClick={() => setPopupAiLetter(true)}
						>
							<Typography variant="h6" component="h6">
								AI Letter
							</Typography>
							<Badge badgeContent="Beta">
								<GetApp className={classes.icons} />
							</Badge>
						</Button>
					</Grid>
				)}
				{agentLetterVisible && (
					<Grid item>
						<Button
							variant="text"
							className={`${classes.forwardButton} ${classes.backButton}`}
							onClick={() => setPopupOffmarket(true)}
						>
							<Typography variant="h6" component="h6">
								Offmarket
							</Typography>
							<GetApp className={classes.icons} />
						</Button>
					</Grid>
				)}
				{customOwnerLetterVisible && (
					<Grid item>
						<Button
							variant="text"
							className={`${classes.forwardButton} ${classes.backButton}`}
							onClick={() => setCustomTemplatePopup(true)}
						>
							<Typography variant="h6" component="h6">
								Owner Letter
							</Typography>
							<GetApp className={classes.icons} />
						</Button>
					</Grid>
				)}
				{viewGenerateYourOwnPdf && (
					<Grid item>
						<Button
							variant="text"
							className={`${classes.forwardButton} ${classes.backButton}`}
							onClick={() => setGenerateCustomPopup(true)}
						>
							<Typography variant="h6" component="h6">
								Generate custom template
							</Typography>
							<GetApp className={classes.icons} />
						</Button>
					</Grid>
				)}
				{viewCRM && (
					<Grid item>
						<Button
							variant="text"
							className={`${classes.forwardButton} ${classes.backButton}`}
							onClick={() => onClickSendToFUB(true)}
						>
							<Typography variant="h6" component="h6">
								Send to CRM
							</Typography>
							{sendToCRMLoading ? (
								<>
									<CircularProgress size={20} />
								</>
							) : (
								<>
									<AssignmentInd className={classes.icons} />
								</>
							)}
						</Button>
					</Grid>
				)}
			</Grid>
			{popupDoorKnock && (
				<Dialog
					open={true}
					onClose={handlePopupClose}
					PaperProps={{ classes: { root: classes.customDialog } }}
				>
					<DialogContent className={classes.creditServiceDialogContent}>
						<Grid>
							<Grid>
								<FormControlLabel
									label="DoorKnock"
									disabled={checkedDoorKnock === 'disabled'}
									control={
										<Checkbox
											color="primary"
											checked={
												checkedDoorKnock === true ||
												checkedDoorKnock === 'disabled'
											}
											onChange={() => setCheckedDoorKnock(!checkedDoorKnock)}
										/>
									}
								/>
								<Box className={classes.serviceBox}>
									<InfoIcon />
									<Typography variant="subtitle1">2 credit</Typography>
								</Box>
							</Grid>

							<Grid>
								<FormControlLabel
									label="Send Letter"
									disabled={checkedSendLetter === 'disabled'}
									control={
										<Checkbox
											color="primary"
											checked={
												checkedSendLetter === true ||
												checkedSendLetter === 'disabled'
											}
											onChange={() =>
												setCheckedSendLetter(!checkedSendLetter)
											}
										/>
									}
								/>
								<Box className={classes.serviceBox}>
									<InfoIcon />
									<Typography variant="subtitle1">1 credit</Typography>
								</Box>
							</Grid>
							<Typography variant="h4" className={classes.numberOfCredits}>
								Total {calcCredits()} Credits
							</Typography>
							<Grid>
								<Typography variant="h4" className={classes.creditsLeft}>
									Credits Left {token}
								</Typography>
								<Box className={classes.serviceBox}>
									<InfoIcon />
									<Typography variant="subtitle1">
										Used credits are non-refundable
									</Typography>
								</Box>
							</Grid>
							<Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
								<Button
									className={classes.cancelButton}
									onClick={() => handleCancelPopupDoorKnock(false)}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									color="primary"
									className={classes.button}
									onClick={(e) => handleSubmitOrderConnection(e)}
									disabled={
										checkedDoorKnock === 'disabled' &&
										checkedSendLetter === 'disabled'
									}
								>
									Confirm
								</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			)}
			{generateCustomPopup && (
				<Dialog open={true} onClose={handlePopupClose} fullWidth maxWidth="md">
					<DialogTitle>Create your own Customizable PDF</DialogTitle>
					<DialogContent>
						{popupLoading ? (
							<CircularProgress
								className={classes.spinner}
								style={{ justifyContent: 'center', display: 'flex' }}
							/>
						) : pdfOwnerUrl ? (
							<a
								href={pdfOwnerUrl}
								target="_blank"
								rel="noopener noreferrer"
								download={`${listing.MLNum}_${listing.Address}_${listing.City}`}
							>
								<Button
									variant="contained"
									color="primary"
									onClick={() => setPopupOwner(false)}
								>
									Download PDF
								</Button>
							</a>
						) : ownerLetterError ? (
							<Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
								Sorry, no properties found
							</Typography>
						) : (
							<div style={{ display: 'flex' }}>
								<div className="text-fields-container" style={{ flex: 1 }}>
									<TextField
										label="Intro"
										multiline
										rows={4}
										fullWidth
										value={
											activeSteps === 0
												? intro1
												: activeSteps === 1
												? intro2
												: intro3
										}
										onChange={(e) =>
											handleTextFieldChange(
												activeSteps,
												'intro',
												e.target.value
											)
										}
									/>
									<TextField
										label="Your Home"
										multiline
										rows={4}
										fullWidth
										value={
											activeSteps === 0
												? home1
												: activeSteps === 1
												? home2
												: home3
										}
										onChange={(e) =>
											handleTextFieldChange(
												activeSteps,
												'home',
												e.target.value
											)
										}
										contentEditable
										onContextMenu={handleContextMenu}
									/>
									<TextField
										label="Your Neighbourhood"
										multiline
										rows={4}
										fullWidth
										value={
											activeSteps === 0
												? neighbourhood1
												: activeSteps === 1
												? neighbourhood2
												: neighbourhood3
										}
										onChange={(e) =>
											handleTextFieldChange(
												activeSteps,
												'neighbourhood',
												e.target.value
											)
										}
										contentEditable
										onContextMenu={handleContextMenu}
									/>
									<TextField
										label="Closing"
										multiline
										rows={4}
										fullWidth
										value={
											activeSteps === 0
												? closing1
												: activeSteps === 1
												? closing2
												: closing3
										}
										onChange={(e) =>
											handleTextFieldChange(
												activeSteps,
												'closing',
												e.target.value
											)
										}
										contentEditable
										onContextMenu={handleContextMenu}
									/>
								</div>
								<div
									className="stepper-container"
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-end',
										gap: '90px'
									}}
								>
									<Button
										className={`stepper ${activeSteps === 0 ? 'active' : ''}`}
										onClick={() => handleStepClick(0)}
										style={{
											marginBottom: '8px',
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<div className="stepper-icon">
											{activeSteps === 0 ? (
												<CheckCircleOutlineIcon />
											) : (
												<HelpOutlineIcon />
											)}
										</div>
										<div
											className="stepper-label"
											style={{ marginLeft: '5px' }}
										>
											terminated/expired property
										</div>
									</Button>
									<Button
										className={`stepper ${activeSteps === 1 ? 'active' : ''}`}
										onClick={() => handleStepClick(1)}
										style={{
											marginBottom: '8px',
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<div className="stepper-icon">
											{activeSteps === 1 ? (
												<CheckCircleOutlineIcon />
											) : (
												<HelpOutlineIcon />
											)}
										</div>
										<div
											className="stepper-label"
											style={{ marginLeft: '5px' }}
										>
											sold property
										</div>
									</Button>
									<Button
										className={`stepper ${activeSteps === 2 ? 'active' : ''}`}
										onClick={() => handleStepClick(2)}
										style={{ display: 'flex', alignItems: 'center' }}
									>
										<div className="stepper-icon">
											{activeSteps === 2 ? (
												<CheckCircleOutlineIcon />
											) : (
												<HelpOutlineIcon />
											)}
										</div>
										<div
											className="stepper-label"
											style={{ marginLeft: '5px' }}
										>
											no assessment value
										</div>
									</Button>
								</div>
							</div>
						)}
					</DialogContent>
					{!popupLoading && (
						<DialogActions>
							<Button>Save Template</Button>
							<Button onClick={handleGenerateYourOwnerPdf}>Generate PDF</Button>
						</DialogActions>
					)}
				</Dialog>
			)}
			{popupAiLetter && (
				<Dialog
					open={true}
					onClose={handlePopupClose}
					PaperProps={{ classes: { root: classes.customDialog } }}
				>
					<DialogTitle>Download AI Letter</DialogTitle>
					<DialogContent className={classes.dialogContent}>
						{popupLoading ? (
							<CircularProgress className={classes.spinner} />
						) : pdfCustomOwnerUrl ? (
							<a
								href={pdfCustomOwnerUrl}
								target="_blank"
								rel="noopener noreferrer"
								download={`${listing.MLNum}_${listing.Address}_${listing.City}`}
							>
								<Button
									variant="contained"
									color="primary"
									onClick={() => setPopupOwner(false)}
								>
									Download PDF
								</Button>
							</a>
						) : aiLetterError ? (
							<Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
								AI letter unavailable for this property
							</Typography>
						) : (
							<Grid>
								<Grid>
									<Button
										variant="contained"
										color="primary"
										className={classes.button}
										onClick={() =>
											handleAiLetter(
												'Generate PDF',
												checkedLetterheadAILetter,
												checkedUsernameAILetter
											)
										}
									>
										Generate PDF
									</Button>
								</Grid>
								<Grid>
									<FormControlLabel
										label="Letterhead"
										control={
											<Checkbox
												color="primary"
												checked={checkedLetterheadAILetter}
												onChange={(e) =>
													handleChangeLetterhead(e, 'AILetter')
												}
											/>
										}
									/>
								</Grid>
								<Grid>
									<FormControlLabel
										label="First and Last name"
										control={
											<Checkbox
												color="primary"
												checked={checkedUsernameAILetter}
												onChange={(e) =>
													handleChangeUsername(e, 'AILetter')
												}
											/>
										}
									/>
								</Grid>
							</Grid>
						)}
					</DialogContent>
				</Dialog>
			)}
			{popupOffmarket && (
				<Dialog
					open={true}
					onClose={handlePopupClose}
					PaperProps={{ classes: { root: classes.customDialog } }}
				>
					<DialogTitle>Download PDF</DialogTitle>
					<DialogContent className={classes.dialogContent}>
						{popupLoading ? (
							<CircularProgress className={classes.spinner} />
						) : pdfOffmarketUrl ? (
							<a href={pdfOffmarketUrl} target="_blank" rel="noopener noreferrer">
								<Button
									variant="contained"
									color="primary"
									onClick={() => setPopupOffmarket(false)}
								>
									Open PDF
								</Button>
							</a>
						) : offmarketError ? (
							<Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
								Sorry, no properties found
							</Typography>
						) : (
							<Grid>
								<Grid>
									<Button
										variant="contained"
										color="primary"
										className={classes.button}
										onClick={() => handleOffmarketPdf(listing.location)}
									>
										Generate PDF
									</Button>
								</Grid>
							</Grid>
						)}
					</DialogContent>
				</Dialog>
			)}
			{customTemplatePopup && (
				<Dialog
					open={true}
					onClose={handlePopupClose}
					PaperProps={{ classes: { root: classes.customDialog } }}
				>
					<DialogTitle>Download Customized Owner Letter</DialogTitle>
					<DialogContent className={classes.dialogContent}>
						{popupLoading ? (
							<CircularProgress className={classes.spinner} />
						) : pdfCustomOwnerUrl ? (
							<a
								href={pdfCustomOwnerUrl}
								target="_blank"
								rel="noopener noreferrer"
								download={`${listing.MLNum}_${listing.Address}_${listing.City}`}
							>
								<Button
									variant="contained"
									color="primary"
									onClick={() => setPopupOwner(false)}
								>
									Download PDF
								</Button>
							</a>
						) : ownerLetterError ? (
							<Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
								Sorry, no properties found
							</Typography>
						) : (
							<Grid>
								<Grid>
									<Button
										variant="contained"
										color="primary"
										className={classes.button}
										onClick={() =>
											handleCustomPdf(
												'Generate PDF',
												checkedLetterheadCustomPDF,
												checkedUsernameCustomPDF
											)
										}
									>
										Generate PDF
									</Button>
									{/* <Button
									variant="contained"
									color="primary"
									className={classes.button}
									onClick={() =>
										handleOwnerPdf('Generate PDF With Map', checked)
									}
								>
									Generate PDF With Map
								</Button> */}
								</Grid>
								<Grid>
									<FormControlLabel
										label="Letterhead"
										control={
											<Checkbox
												color="primary"
												checked={checkedLetterheadCustomPDF}
												onChange={(e) =>
													handleChangeLetterhead(e, 'CustomPDF')
												}
												//inputProps={{ 'aria-label': name }}
											/>
										}
									/>
								</Grid>
								<Grid>
									<FormControlLabel
										label="First and Last name"
										control={
											<Checkbox
												color="primary"
												checked={checkedUsernameCustomPDF}
												onChange={(e) =>
													handleChangeUsername(e, 'CustomPDF')
												}
												//inputProps={{ 'aria-label': name }}
											/>
										}
									/>
								</Grid>
							</Grid>
						)}
					</DialogContent>
				</Dialog>
			)}
			<Paper>
				<Grid container className={classes.listTitleAndMapContainer}>
					<Grid item xs={12} md={7}>
						<Grid item xs={12}>
							{/* THE LIST TITLE INCLUDES THE PROPERTIES NAME, CHIPS, AND INFO ICONS */}
							<DetailsPageListTitle
								listing={listing}
								setIsLoading={setIsLoading}
								checkListingHistory={checkListingHistory}
								shouldRefetch={shouldRefetch}
								setShouldRefetch={setShouldRefetch}
								addCommentsVisible={addCommentsVisible}
								tagsVisible={tagsVisible}
								activeStep={activeStep}
								setActiveStep={setActiveStep}
								currentPropertyIndex={currentPropertyIndex}
								totalNumProperties={totalNumProperties}
								setCurrentPropertyIndex={setCurrentPropertyIndex}
								resetPermissions={resetPermissions}
								isLoadingSecondary={isLoadingSecondary}
								setIsLoadingSecondary={setIsLoadingSecondary}
								updateChipsForFollowUp={updateChipsForFollowUp}
								isUsingHomeId={isUsingHomeId}
								chipData={chipData}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={5} className={classes.mapContainer}>
						<Map
							address={`${listing.Address}, ${listing.Region}, ${listing.PostalCode}`}
							point={listing.location}
						/>
					</Grid>
				</Grid>
				<Grid className={classes.tabsContainer}>
					<DetailsPageTabs
						listing={listing}
						userNavigatedToNewProperty={userNavigatedToNewProperty}
						shouldRefetch={shouldRefetch}
						setShouldRefetch={setShouldRefetch}
						assignedInfo={assignedInfo}
						setAssignedInfo={setAssignedInfo}
						knockingStatusVisible={knockingStatusVisible}
						editAssignedTaskVisible={editAssignedTaskVisible}
						viewCommentsVisible={viewCommentsVisible}
						tagsVisible={tagsVisible}
						viewCallRecordingsVisible={viewCallRecordingsVisible}
						addCommentsVisible={addCommentsVisible}
						permissionChecked={permissionChecked}
						setPermissionChecked={setPermissionChecked}
						resetPermissions={resetPermissions}
						viewTopAgentsVisible={viewTopAgentsVisible}
						checkListingHistory={checkListingHistory}
						setCheckListingHistory={setCheckListingHistory}
						assignMyself={assignMyself}
						setAssignMyself={setAssignMyself}
						viewEditHistoryVisible={viewEditHistoryVisible}
						viewOwnerName={viewOwnerName}
						viewOwnerPhone={viewOwnerPhone}
						ownerDetailsInformation={ownerDetailsInformation}
						ownerInfoLoading={ownerInfoLoading}
						isUsingHomeId={isUsingHomeId}
					/>
				</Grid>
			</Paper>

			{/* PREVIOUS AND NEXT BUTTONS */}
			{currentPropertyIndex != null && (
				<Grid
					style={{ marginTop: '2rem' }}
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					{currentPropertyIndex !== 0 ? (
						<Button
							className={classes.bottomButtons}
							variant="outlined"
							onClick={handlePreviousProperty}
						>
							<KeyboardArrowLeft className={classes.icons} />
							Prev Property
						</Button>
					) : (
						<div></div> // to preserve styling
					)}
					{currentPropertyIndex !== totalNumProperties - 1 ? (
						<Button
							className={classes.bottomButtons}
							variant="outlined"
							onClick={handlNextProperty}
						>
							Next Property
							<KeyboardArrowRight className={classes.icons} />
						</Button>
					) : (
						<div></div> // to preserve styling
					)}
				</Grid>
			)}
		</BaseLayout>
	) : (
		<BaseLayout>
			<NotFoundError listing={MLNum} />
		</BaseLayout>
	);
}

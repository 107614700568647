import React, { useEffect, useState, useRef } from 'react';
//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';
import { useUserState } from '../../../hook/customerHook';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Badge from '@material-ui/core/Badge';
import AssignTasks from '../../comps/DetailsPageTabs/CustomTab/EditPopUp/FormFields/AssignTasks';
import Alert from '@material-ui/lab/Alert';

import {
	saveSelection,
	deleteFromCollection,
	searchMyCollection,
	searchListing,
	getRoutesForSelection,
	getActiveAgents,
	submitPropertyCustomData,
	getPdfOfMultipleListings,
	getPdfOfCustomMultipleListing,
	sendToFollowUpBoss
} from '../../../api/serverRequests';

import { imageResolver } from '../../../helper/image-resolver';
import CusPaginationActions from './CusPaginationActions';
import { cusTheme } from './cusTheme';
import {
	currencyParser,
	correctKnockabilityValue,
	displayHumanReadableDate
} from '../../../helper/parser';
import ReactToPrint from 'react-to-print';
import {
	IconButton,
	Button,
	Tooltip,
	CircularProgress,
	Snackbar,
	FormControlLabel
} from '@material-ui/core';
import { Print } from '@material-ui/icons';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import MapResults from '../MapResults';
import { withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import ListingTag from '../ListingTag';
import {
	getHomeCommentsByOrganization,
	getHomeTagsByOrganization
} from '../../../api/serverRequests';
import moment from 'moment';

import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

const Avatar = loadable(() => import('@material-ui/core/Avatar'));
const Checkbox = loadable(() => import('@material-ui/core/Checkbox'));
const Table = loadable(() => import('@material-ui/core/Table'));
const TableBody = loadable(() => import('@material-ui/core/TableBody'));
const TableCell = loadable(() => import('@material-ui/core/TableCell'));
const TableContainer = loadable(() => import('@material-ui/core/TableContainer'));
const TablePagination = loadable(() => import('@material-ui/core/TablePagination'));
const TableRow = loadable(() => import('@material-ui/core/TableRow'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const CusTableHead = loadable(() => import('./CusTableHead'));
const CusTableToolbarOffMarket = loadable(() => import('./CusTableToolbarOffMarket'));
const VisibilityIcon = loadable(() => import('@material-ui/icons/Visibility'));

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		flex: 1,
		color: blue[900],
		margin: 10,
		justifyContent: 'center',
		alignSelf: 'center',

		'&.MuiButton-root.active': {
			color: '#FF0000',
			borderColor: '#FF0000'
		}
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	dialogContent: {
		width: '500px',
		height: '155px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-end',
		marginBottom: '14px',
		justifyContent: 'center',
		alignItems: 'center'
	}
});
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const useStyles = (props) =>
	makeStyles((theme) => ({
		collectionInUser: {
			backgroundColor: cusTheme.palette.primary.light
		},
		collectionInOrganization: {
			backgroundColor: cusTheme.palette.secondary.light
		},
		pic: {
			backgroundColor: theme.palette.text.secondary,
			width: '42px',
			height: '42px'
		},
		tableContainer: {
			height: '80vh',
			overflowY: 'auto'
		},
		printIcon: {
			'&:hover, &.Mui-focusVisible': { backgroundColor: 'white' }
		},
		printIconContainer: {
			display: 'flex',
			width: '100%',
			justifyContent: 'flex-end'
		},
		mapButton: {
			margin: theme.spacing(1)
		},
		pagination: {
			'&.MuiTableCell-root': {
				paddingTop: theme.spacing(2),
				border: 'none'
			},
			'& .MuiToolbar-root': {
				flexWrap: 'wrap',
				justifyContent: 'flex-end'
			},
			'& .MuiTablePagination-selectRoot': {
				marginRight: 0
			},
			'& .MuiTablePagination-caption': {
				marginLeft: theme.spacing(1),
				[theme.breakpoints.up('sm')]: {
					marginLeft: theme.spacing(4)
				}
			}
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500]
		},
		summaryButton: {
			height: '10px',
			width: '10px',
			fontSize: '10px',
			color: '#004F2B'
		},
		printButtons: {
			marginTop: '30px',
			marginLeft: '98px',
			display: 'flex',
			alignItems: 'space-between'
		},
		dialogContent: {
			width: '250px',
			height: '130px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'flex-end',
			marginBottom: '14px',
			flexDirection: 'column'
		},
		dialogPrintContent: {
			width: '250px',
			height: '130px',
			display: 'flex',
			flexDirection: 'column'
		},
		popupLabel: {
			marginTop: '20px'
		},
		checkBox: {
			backgroundColor: 'blue',
			cursor: 'pointer',
			accentColor: '#004F2B'
		},
		printListings: {
			fontSize: '15px',
			marginLeft: '25px',
			fontWeight: 'bold'
		},
		printScaleText: {
			fontSize: '10px',
			marginLeft: '25px',
			marginTop: '-14px',
			color: 'grey'
		},
		comingSoon: {
			fontSize: '10px',
			fontStyle: 'italic',
			color: 'grey'
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff'
		},
		hoverElement: {
			position: 'relative',
			'&:hover': {
				'& $hoverBox': {
					display: 'block'
				}
			}
		},
		hoverBox: {
			zIndex: 100,
			display: 'none',
			background: 'white',
			border: '1px solid gray',
			position: 'absolute',
			padding: '0 1rem',
			whiteSpace: 'nowrap'
		},
		hoverBox_tags: {
			color: '#3f51b5'
		},
		commentBox: {
			margin: '10px',
			'& h6': {
				whiteSpace: 'nowrap'
			},
			'& span': {
				fontSize: '1rem'
			}
		},
		spinner: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%',
			margin: '2rem'
		},
		resultsContainer: {
			display: 'grid',
			gap: theme.spacing(2),

			[theme.breakpoints.up(1100)]: {
				gap: theme.spacing(1)
			}
		},
		priceLine: {
			borderBottom: '1px solid lightgray',
			padding: theme.spacing(1, 0),
			marginBottom: theme.spacing(1),
			gap: theme.spacing(1),

			[theme.breakpoints.up(1100)]: {
				display: 'inline-flex',
				flexFlow: 'column nowrap',
				width: 'unset !important',
				borderBottom: 'none',
				padding: '0',
				marginBottom: '0',
				gap: '0',
				justifyContent: 'center',
				textAlign: 'center'
			}
		},
		listingAddress: {
			fontWeight: 'bold',

			'& a': {
				color: 'black',
				textDecoration: 'none'
			}
		},
		mlsLine: {
			color: theme.palette.text.disabled
		},
		topRow: {
			alignItems: 'center',
			justifyContent: 'space-between',
			flexFlow: 'row nowrap',
			width: '100%',

			[theme.breakpoints.up(1100)]: {
				justifyContent: '',
				display: 'inline-flex',
				width: 'unset !important'
			}
		},
		smallFavorite: {
			[theme.breakpoints.up(1100)]: {
				display: 'none'
			}
		},
		bigFavorite: {
			[theme.breakpoints.down(1100)]: {
				display: 'none'
			}
		},
		additionalInfo: {
			[theme.breakpoints.up(1100)]: {
				display: 'inline-flex',
				flexFlow: 'column nowrap'
			}
		},
		assessedLine: {
			[theme.breakpoints.up(1100)]: {
				color: theme.palette.text
			}
		},
		contentContainer: {
			[theme.breakpoints.up(1100)]: {
				display: 'grid',
				padding: '10px 14px',
				paddingBottom: '10px !important',
				justifyContent: 'space-between',
				gridTemplateColumns: props.isSearchActivePage
					? '350px 250px auto auto'
					: '300px 250px auto auto',
				'&:hover': {
					backgroundColor: theme.palette.grey['KN_back_ground'] // Color when hovered
				}
			}
		},
		avatarAddress: {
			alignItems: 'center',
			display: 'flex',
			flexFlow: 'row nowrap',
			flexGrow: '1',
			gap: theme.spacing(1.5)
		},
		customDialog: {
			maxWidth: '500px',
			width: '100%'
		},
		dialogContent: {
			height: '155px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'flex-end',
			marginBottom: '14px',
			justifyContent: 'center',
			alignItems: 'center'
		},
		dialogContentAgents: {
			height: '155px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'flex-end',
			marginBottom: '14px',
			justifyContent: 'center',
			alignItems: 'center'
		}
	}));

const DialogTitle = withStyles(styles)(({ children, classes, onClose, ...other }) => {
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const StyledBadge = withStyles((theme) => ({
	badge: {
		backgroundColor: 'var(--badge-color)',
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		'&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			border: '1px solid currentColor',
			content: ''
		}
	}
}))(Badge);

function getKnockabilityColor(knockability) {
	if (knockability >= 80) {
		return '#57A75B';
	} else if (knockability >= 50) {
		return '#FFCE22';
	} else {
		return '#FF0000';
	}
}

function ListingCard({
	mlNum,
	status,
	homeId,
	address,
	price,
	assessment,
	beds,
	bathrooms,
	expiryDate,
	kitchens,
	totalArea,
	selected,
	favSelected,
	setFavSelected,
	knockability,
	pic,
	row,
	depth,
	frontage,
	propertyArea,
	onCheckClick,
	getAddress,
	selectedRoute,
	planRoutes,
	openInNewTab,
	AssignedAt,
	isTaskBoard,
	isSearchActivePage,
	...other
}) {
	const areaRounded = Math.round(parseFloat(totalArea));
	const linkToDetailsPage = mlNum ? mlNum : homeId;
	const formatLotSize = () => {
		const cleanValue = (value) => {
			if (value && value.length > 1 && value.includes('(')) {
				return parseInt(value.split('(')[0].trim());
			}
			return parseInt(value);
		};

		const depthFixed = cleanValue(depth) || '-';
		const frontageFixed = cleanValue(frontage) || '-';
		const areaRounded1 = areaRounded || '-';

		// Build the string with the desired format
		return `${frontageFixed}\u00D7${depthFixed} (${areaRounded1})`;
	};

	const classes = useStyles({ isSearchActivePage })();
	const [checked, setChecked] = useState(favSelected);
	const navigation = useHistory();
	const theme = useTheme();
	const renderAssessmentSearchActive = () => {
		if (!isSearchActivePage) return;
		if (row['DealType'] === 'price_dropped') {
			return (
				<Typography className={classes.assessedLine} variant="body1">
					{row['PrevPrice'] > 0
						? 'Prev ' +
						  parseInt(row['PrevPrice']).toLocaleString('en-US', {
								style: 'currency',
								currency: 'USD',
								minimumFractionDigits: 0,
								maximumFractionDigits: 0
						  })
						: 'No previous price yet.'}{' '}
					({row['Percent']}%)
				</Typography>
			);
		} else if (row['DealType'] === 'active') {
			return (
				<Typography className={classes.assessedLine} variant="body1">
					{row['LastSoldPrice'] > 0
						? 'Last sold for ' + currencyParser(row['LastSoldPrice'])
						: 'No last sold price found.'}{' '}
					({row['Percent']}%)
				</Typography>
			);
		} else if (row['DealType'] === 'below_estimate') {
			return (
				<Typography className={classes.assessedLine} variant="body1">
					{row['EstimateMid'] > 0
						? 'Below estimation of ' + currencyParser(row['EstimateMid'])
						: 'No estimation found.'}{' '}
					({row['Percent']}%)
				</Typography>
			);
		} else {
			return (
				<Typography className={classes.assessedLine} variant="body1">
					{row['Assessment'] > 0
						? 'Assessed for ' + currencyParser(row['Assessment'])
						: 'No assessment yet.'}{' '}
					{!isNaN(row['Percent']) ? `(${row['Percent']}%)` : ''}
				</Typography>
			);
		}
	};
	return (
		<Card
			className={classes.root}
			variant="outlined"
			style={{
				borderColor: selectedRoute ? theme.palette.primary.main : '1px solid lightgray',
				backgroundColor: selectedRoute && 'rgba(72, 193, 198, 0.1)'
			}}
		>
			<CardContent className={classes.contentContainer}>
				<Grid direction="row" container spacing={2} className={classes.topRow}>
					{!planRoutes && (
						<Checkbox
							inputProps={{
								'aria-labelledby': mlNum + '__' + homeId
							}}
							id="selectedListing"
							checked={selected}
							onClick={(event) => {
								onCheckClick(event, row['MLNum'] + '__' + row['HomeId'], address);
								getAddress(event, address);
							}}
						/>
					)}
					<Grid className={classes.avatarAddress} item direction="row">
						{knockability !== 0 ? (
							<StyledBadge
								className={classes.knockabilityBadge}
								overlap="circular"
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right'
								}}
								variant="dot"
								style={{
									'--badge-color': getKnockabilityColor(
										correctKnockabilityValue(knockability)
									)
								}}
							>
								<Avatar
									className={classes.pic}
									variant="circular"
									alt=""
									src={imageResolver(pic)}
								/>
							</StyledBadge>
						) : (
							<Avatar
								className={classes.pic}
								variant="circular"
								alt=""
								src={imageResolver(pic)}
							/>
						)}
						<Typography className={classes.listingAddress}>
							<a
								href={`/user/listing/${linkToDetailsPage}`}
								rel="noopener noreferrer"
								onClick={(event) => {
									event.preventDefault();
									if (openInNewTab)
										window.open(`/user/listing/${linkToDetailsPage}`);
									else navigation.push(`/user/listing/${linkToDetailsPage}`);
								}}
							>
								{isSearchActivePage ? `${address}, ${row['Area']}` : address}
							</a>
							{isTaskBoard && (
								<Typography className={classes.assessedLine}>
									Assigned {displayHumanReadableDate(AssignedAt)}
								</Typography>
							)}
							{renderAssessmentSearchActive()}
						</Typography>
					</Grid>

					<Checkbox
						className={classes.smallFavorite}
						icon={<FavoriteBorder />}
						checkedIcon={<Favorite />}
						name="checkedH"
						checked={checked}
						onClick={(event) => {
							const isChecked = event.target.checked;
							const listings = [mlNum + '__' + homeId];
							if (isChecked) {
								saveSelection({ listings });
								setFavSelected((prevSelected) => [...prevSelected, homeId]);
							} else {
								deleteFromCollection({ listings });
								setFavSelected((prevSelected) =>
									prevSelected.filter((id) => id !== homeId)
								);
							}
							setChecked(!checked);
						}}
					/>
				</Grid>

				<Grid container item direction="row" className={classes.priceLine}>
					<Typography>{price ? currencyParser(price) : ''}</Typography>
					<Typography className={classes.assessedLine}>
						{assessment > 0 ? `Assessed for ${currencyParser(assessment)}` : ''}
					</Typography>
				</Grid>
				<Grid item className={classes.additionalInfo}>
					<Typography>
						{beds && beds > 0 ? `${beds} bd` : ''}
						{beds && beds > 0 ? ' • ' : ''}
						{bathrooms ? `${bathrooms} ba` : ''}
						{bathrooms ? ` • ` : ''}
						{kitchens ? `${kitchens} k` : ''}
						{kitchens ? ' • ' : ''}
						{propertyArea ? `${propertyArea} sf` : ''}
						{propertyArea ? ' • ' : ''}
						{formatLotSize() && ` ${formatLotSize()}`}
					</Typography>
					{status === 'A' || status === 'AU' ? (
						<Typography className={classes.mlsLine}>
							MLS {mlNum} Listed on {row['ListDate']}
						</Typography>
					) : status === 'S' || status === 'P' || status === 'F' ? (
						<Typography className={classes.mlsLine}>
							MLS {mlNum} Closed on {row['SoldDate']}
						</Typography>
					) : status === 'T' || status === 'C' || status === 'X' ? (
						<Typography className={classes.mlsLine}>
							MLS {mlNum} Expired on {expiryDate}
						</Typography>
					) : (
						''
					)}
				</Grid>
				<Checkbox
					className={classes.bigFavorite}
					icon={<FavoriteBorder />}
					checkedIcon={<Favorite />}
					name="checkedH"
					checked={checked}
					onClick={(event) => {
						const isChecked = event.target.checked;
						const listings = [mlNum + '__' + homeId];
						if (isChecked) {
							saveSelection({ listings });
							setFavSelected((prevSelected) => [...prevSelected, homeId]);
						} else {
							deleteFromCollection({ listings });
							setFavSelected((prevSelected) =>
								prevSelected.filter((id) => id !== homeId)
							);
						}
						setChecked(!checked);
					}}
				/>
			</CardContent>
		</Card>
	);
}

export default function Lists({
	listings,
	setListings,
	title,
	listHeads,
	listCells,
	handleSelect,
	handleDelete,
	isSearch,
	initialOrderBy,
	initialSortOrder,
	isDetailsTable,
	isLoading,
	setIsLoading,
	filters,
	selectedRoute,
	destination,
	editAssignedTaskVisible,
	assignMyself,
	planRoutes,
	openInNewTab = false,
	isTaskBoard,
	isSearchActivePage = false,
	combineFilters,
	setRefreshSearch
}) {
	const updatePaginationStateSessionStorage = (key, value) => {
		const savedState = sessionStorage.getItem('paginationState');
		const currentState = savedState ? JSON.parse(savedState) : {};
		sessionStorage.setItem(
			'paginationState',
			JSON.stringify({
				...currentState,
				[key]: value
			})
		);
	};

	const getInitialPage = () => {
		let savedState = sessionStorage.getItem('paginationState');
		savedState = JSON.parse(savedState);

		if (savedState && (savedState.page !== undefined || savedState.page !== null)) {
			return savedState.page;
		} else {
			updatePaginationStateSessionStorage('page', 0);
			return 0;
		}
	};

	const getInitialRowsPerPage = () => {
		let savedState = sessionStorage.getItem('paginationState');
		savedState = JSON.parse(savedState);

		if (savedState && savedState.rowsPerPage) {
			return savedState.rowsPerPage;
		} else {
			updatePaginationStateSessionStorage('rowsPerPage', 10);
			return 10;
		}
	};

	const classes = useStyles();
	const [order, setOrder] = useState(initialSortOrder || 'desc');
	const [orderBy, setOrderBy] = useState(initialOrderBy);
	const [selected, setSelected] = useState([]);
	const [addressSelected, setAddressSelected] = useState([]);
	const [favSelected, setFavSelected] = useState([]);
	const [rows, setRows] = useState(listings);
	const [page, setPage] = useState(getInitialPage);
	const [rowsPerPage, setRowsPerPage] = useState(getInitialRowsPerPage);
	const [on, setOn] = useState(false);
	const [showPrint, setShowPrint] = useState(false);
	const [mapResultsOpen, setMapResultsOpen] = useState(false);
	const [newListings, setNewListings] = useState([]);
	const [oldRows, setOldRows] = useState([0]);
	const [printType, setPrintType] = useState();
	const [userState, dispatchUser] = useUserState();
	const [selectAgentOptions, setSelectAgentOptions] = useState([]);
	const [owner, setOwner] = useState(null);
	const [assignedInfo, setAssignedInfo] = useState({}); // object that holds active agents
	const [formSubmitObject, setFormSubmitObject] = useState({});
	const [open, setOpen] = useState(false);
	const [offset, setOffset] = useState(100);

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	// ownerletter
	const [popupOwner, setPopupOwner] = useState(false);
	const [popupCustomOwner, setPopupCustomOwner] = useState(false);
	const [popupLoading, setPopupLoading] = useState(false);
	const [pdfOwnerUrl, setPdfOwnerUrl] = useState('');
	const [pdfCustomOwnerUrl, setPdfCustomOwnerUrl] = useState('');
	const [ownerLetterError, setOwnerLetterError] = useState(false);
	const [checkedLetterhead, setCheckedLetterhead] = useState(userState.checkedLetterhead);
	const [checkedUsername, setCheckedUsername] = useState(userState.checkedUsername);
	const [selectAll, setSelectAll] = useState(false);
	const [sendToCRMLoading, setSendToCRMLoading] = useState(false);

	const navigation = useHistory();
	function descendingComparator(a, b, orderBy) {
		if (orderBy === 'Route') {
			return 0;
		}
		if (orderBy === 'Percent') {
			b[orderBy] = parseFloat(b[orderBy]);
			a[orderBy] = parseFloat(a[orderBy]);
		}
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function filterListings() {
		let temp = [];

		for (var i = 0; i < listings.length; i++) {
			if (selected.includes(String(listings[i].MLNum) + '__' + String(listings[i].HomeId))) {
				temp.push(listings[i]);
			}
		}

		setNewListings(temp);
	}
	async function handleBeforePrint() {
		filterListings();
		await setOldRows(rowsPerPage);
		await setRowsPerPage(-1);
		await setShowPrint(true);
		await delay(5);
	}
	function onAfterPrint() {
		setShowPrint(false);
		setRowsPerPage(oldRows);
	}
	function getComparator(order, orderBy) {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	const handlePopupClose = () => {
		setPopup(false);
		setPopupOwner(false);
		setPopupCustomOwner(false);
	};
	const popupClick = () => {
		setPopup(true);
	};
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = stableSort(rows, getComparator(order, orderBy))
				.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
				.map((row) => row['MLNum'] + '__' + row['HomeId']);
			const newAddressSelecteds = stableSort(rows, getComparator(order, orderBy))
				.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
				.map((row) => row['Address']);

			let mergedSelected = [];
			let mergedAddressSelected = [];
			if (page === 0) {
				mergedSelected = newSelecteds;
				mergedAddressSelected = newAddressSelecteds;
			} else if (page > 0) {
				mergedSelected = selected.concat(newSelecteds);
				mergedAddressSelected = addressSelected.concat(newAddressSelecteds);
			}
			setSelected(mergedSelected);
			setAddressSelected(mergedAddressSelected);
			return;
		}
		setSelected([]);
		setAddressSelected([]);
	};
	let componentRef = useRef();

	const handleClick = (event, name, address) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];
		let newAddressSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
			newAddressSelected = newAddressSelected.concat(addressSelected, address);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
			newAddressSelected = newAddressSelected.concat(addressSelected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
			newAddressSelected = newAddressSelected.concat(addressSelected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
			newAddressSelected = newAddressSelected.concat(
				addressSelected.slice(0, selectedIndex),
				addressSelected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
		setAddressSelected(newAddressSelected);
	};

	const getAddress = (event, address) => {
		const selectedIndex = addressSelected.indexOf(address);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(addressSelected, address);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(addressSelected.slice(1));
		} else if (selectedIndex === addressSelected.length - 1) {
			newSelected = newSelected.concat(addressSelected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				addressSelected.slice(0, selectedIndex),
				addressSelected.slice(selectedIndex + 1)
			);
		}
		setAddressSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		const mergedFilters = combineFilters({});
		const totalPages = Math.ceil(rows.length / rowsPerPage);

		if (mergedFilters && newPage >= totalPages) {
			setIsLoading(true);
			searchListing({ user: userState.user_id, filters: mergedFilters, offset: offset })
				.then((res) => {
					if (res.data.success) {
						let combineListings = [...listings, ...res.data.listings];
						setListings(combineListings);
						setOffset((prevOffset) => prevOffset + 100);
						setPage(newPage);
						setIsLoading(false);
						updatePaginationStateSessionStorage('page', newPage);
						dispatchUser({ offMarketSearchResults: combineListings });
					}
				})
				.catch((err) => {
					setIsLoading(false);
					console.log(err.message);
				});
		} else {
			setPage(newPage);
			updatePaginationStateSessionStorage('page', newPage);
		}
		setSelectAll(false);
		setRefreshSearch(false);
	};

	const handleChangeRowsPerPage = (event) => {
		const currPage = page;
		const currRowPerPage = rowsPerPage;
		const startingItem = currPage * currRowPerPage;

		const nextRowPerPage = parseInt(event.target.value, 10);
		const nextPage = Math.floor(startingItem / nextRowPerPage);
		setRowsPerPage(nextRowPerPage);
		setPage(nextPage);
		updatePaginationStateSessionStorage('rowsPerPage', nextRowPerPage);
		updatePaginationStateSessionStorage('page', nextPage);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	const onClickDelete = () => {
		setIsLoading(true);
		const data = {
			userId: userState.user.user_id,
			organizationId: userState.user.organization,
			listings: selected
		};
		deleteFromCollection(data)
			.then((res) => {
				if (res.data.success) {
					setSubmitSuccessStatus('success');
					setNotificationState(true);
					setNotificationMessage('Selected listings are deleted!');
					setIsLoading(false);
					fetchData();
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setIsLoading(false);
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
				}
				setIsLoading(false);
				console.log(err.message);
			});
		setSelected([]);
	};

	const onClickAssignTask = () => {
		if (handleAssignTask) handleAssignTask(selected, addressSelected);
	};

	const handleChangeLetterhead = (event) => {
		setCheckedLetterhead(event.target.checked);
		dispatchUser({ checkedLetterhead: event.target.checked });
	};

	const handleChangeUsername = (event) => {
		setCheckedUsername(event.target.checked);
		dispatchUser({ checkedUsername: event.target.checked });
	};

	const onClickOwnerLetter = async () => {
		setPdfOwnerUrl('');
		setOwnerLetterError(false);
		setPopupOwner(true);
	};

	const onClickCustomOwnerLetter = async () => {
		setPdfCustomOwnerUrl('');
		setOwnerLetterError(false);
		setPopupCustomOwner(true);
	};

	const extractMlNums = () => {
		const mlNumArr = [];
		// splits the selected array into mlnum
		selected.map((select) => {
			const [mlNum] = select.split('__');
			mlNumArr.push(mlNum);
		});
		return mlNumArr;
	};

	const handleCustomOwnerPdf = async (generateMethod, letterhead, username) => {
		const mlNumArr = extractMlNums();
		setPopupLoading(true);
		getPdfOfCustomMultipleListing(
			mlNumArr,
			userState.user.name,
			'Generate PDF',
			letterhead,
			username
		)
			.then((res) => {
				if (res.status === 200) {
					const downloadUrl = window.URL.createObjectURL(res.data);
					setPdfCustomOwnerUrl(downloadUrl);
					setPopupLoading(false);
				}
			})
			.catch((err) => {
				setPopupLoading(false);
				setOwnerLetterError(true);
				console.log(err);
				if (err.response && err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
				}
				console.error(err.message);
			});
	};

	const onClickSave = () => {
		setIsLoading(true);
		const data = {
			userId: userState.user.user_id,
			organizationId: userState.user.organization,
			listings: selected
		};

		saveSelection(data)
			.then((res) => {
				if (res.data.success) {
					setSubmitSuccessStatus('success');
					setNotificationState(true);
					setNotificationMessage('Selected listings are saved!');
					setIsLoading(false);
					fetchData();
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setIsLoading(false);
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
				}
				setIsLoading(false);

				console.log(err.message);
			});
		setSelected([]);
	};

	const onClickRoutes = () => {
		if (handleRoutes) handleRoutes(selected);
	};

	const onClickPlanMyRoutes = (event) => {
		event.preventDefault();
		dispatchUser({ selectedRoutes: null });
		dispatchUser({ selectedRoutes: extractMlNums(selected) });
		navigation.push({
			pathname: '/user/planning-routes',
			state: { from: navigation.location.pathname }
		});
	};

	const onClickSendToFUB = (event) => {
		setSendToCRMLoading(true);
		const data = {
			userId: userState.user.user_id,
			organizationId: userState.user.organization,
			listings: extractMlNums()
		};
		sendToFollowUpBoss(data)
			.then((res) => {
				if (res.data.success) {
					setSendToCRMLoading(false);
					setNotificationState(true);
					const data = res.data.results;
					const sent = data['sent'];
					const failed = data['failed'];
					if (failed && failed.length <= 0) {
						setSubmitSuccessStatus('success');
						setNotificationMessage(`Successfully sent ${sent.length} lead to the CRM!`);
					} else {
						setSubmitSuccessStatus('error');
						setNotificationMessage(
							`Sent ${sent.length} to the CRM. Failed to send ${failed
								.map((item) => {
									return item;
								})
								.join(', ')}.`
						);
					}
				}
			})
			.catch((err) => {
				if (err.response.status === 400) {
					setSubmitSuccessStatus('error');
					setNotificationState(true);
					setNotificationMessage('Your organization has not set up their API key yet.');
				} else if (err.response.status === 401) {
					setIsLoading(false);
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
				} else if (err.response.status === 406) {
					setSubmitSuccessStatus('error');
					setNotificationState(true);
					setNotificationMessage(
						`No results were found with your selection${
							selected && selected.length > 1 ? 's.' : '.'
						}`
					);
				}
				setIsLoading(false);

				console.log(err.message);
			});
	};

	const onClickPrint = () => {
		console.log('Print');
	};
	const onClickSelect = () => {
		const oldListings = listings;
		if (handleSelect && on === false) {
			handleSelect(selected, on);
		} else {
			handleSelect(oldListings, on);
			// listings = oldListings;
			setSelected(selected);
		}
		//('Select all selected listings');
		setOn(!on);
	};

	const [errMsg, setErrMsg] = useState('');
	const [homeTagsAndComms, setHomeTagsAndComms] = useState({});

	const onHoverAPI = async (e, homeId) => {
		e.preventDefault();
		if (homeTagsAndComms.hasOwnProperty(homeId)) {
			return;
		}
		try {
			const [commentsResp, tagsResp] = await Promise.all([
				getHomeCommentsByOrganization(homeId, 5),
				getHomeTagsByOrganization(homeId)
			]);
			setHomeTagsAndComms((prevState) => ({
				...prevState,
				[homeId]: {
					comments: commentsResp.data.comments,
					tags: tagsResp.data.tags.slice(0, 2),
					loading: false
				}
			}));
		} catch (error) {
			setSubmitSuccessStatus('error');
			if (error.response.status === 401) {
				setNotificationState(true);
				setNotificationMessage(
					'Your connection is time out. Please log in again to continue'
				);
				return;
			}
			setErrMsg('Error while loading tags!');
			console.log(error.message);
		}
	};
	useEffect(() => {
		setRows(listings);
	}, [listings]);

	const fetchData = async (unmounted) => {
		searchMyCollection()
			.then((res) => {
				if (res.status === 200) {
					setFavSelected(res.data.listings.map((listing) => listing.HomeId));
					setIsLoading(false);
				}
				if (!unmounted) {
					setIsLoading(false);
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
				} else if (!unmounted) {
					setIsLoading(false);
					setErrMsg('Error while loading listing!');
				}
				console.log(err.message);
			});
	};

	// fetch favorites from collection
	useEffect(() => {
		let unmounted = false;
		fetchData(unmounted);
		return () => {
			unmounted = true;
		};
	}, []);

	const handleRoutes = (selListings) => {
		if (selListings.length > 25) {
			setSubmitSuccessStatus('error');
			setNotificationState(true);
			setNotificationMessage(
				'The maximum number of selections permitted for route calculation is 25.'
			);
			return;
		}
		// display loading pop up until new tab opened
		setIsLoading(true);
		const data = {
			listings: selListings
		};
		getRoutesForSelection(data)
			.then((res) => {
				if (res.data.success) {
					let url = res.data.result.path;
					window.open(url, '_blank', 'noreferrer');
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage(
						'Your connection is time out. Please log in again to continue'
					);
					dispatchUser({
						isLoggedIn: false
					});
				}
				console.log(err.message);
				setIsLoading(false);
			});
	};

	const addDataToFormObject = (key, value) => {
		// everytime user clicks save we save all data
		setFormSubmitObject((prevObject) => {
			return { ...prevObject, [key]: value };
		});
	};

	const clearKeys = (obj) => {
		// this function clears the form field if the user closes or saves changes
		// the desired keys are kept, so that the edit modal still works
		const desiredKeysToKeep = ['city'];

		return Object.fromEntries(
			Object.entries(obj).filter(([key, value]) => desiredKeysToKeep.includes(key))
		);
	};

	const handleAssignTask = (listings, addressSelected) => {
		const fetchAgentOptionsIdValue = async (mapAgentInfoToOptions) => {
			// if (editAssignedTaskVisible) {
			const agentsRes = await getActiveAgents();
			try {
				if (agentsRes.data.success) {
					if (assignMyself) {
						let options = [];
						options.push({
							label: userState['user'].name,
							value: userState['user'].user_id
						});
						options.push({ label: 'Unassign', value: '-1' });
						setSelectAgentOptions(options);
					} else {
						const options = mapAgentInfoToOptions(agentsRes.data.agents);
						options.push({ label: 'Unassign', value: '-1' });
						setSelectAgentOptions(options);
						console.log('what is the agent options?', selectAgentOptions);
					}
				}
			} catch (err) {
				if (agentsRes.status === 401) {
					return dispatchUser({ isLoggedIn: false });
				}
				console.log(err.message);
			}
			// }
		};

		const mapAgentInfoToOptions = (agentArr) => {
			// this function formats the possible options to an array holding objects of the form {name: string, uuid: string}
			return agentArr.map((agent) => {
				return { label: agent.name, value: agent.uuid };
			});
		};

		fetchAgentOptionsIdValue(mapAgentInfoToOptions);
		setOpen(true);
		const mlNumArr = [];
		const homeIdArr = [];
		listings.map((select) => {
			const [mlNum, homeId] = select.split('__');
			mlNumArr.push(mlNum);
			homeIdArr.push(homeId);
		});
		addDataToFormObject('HomeIds', homeIdArr);
		addDataToFormObject('homeId', homeIdArr[0]);
		addDataToFormObject('mlNum', mlNumArr[0]);
		addDataToFormObject('HomeAddresses', addressSelected);
	};

	const handleSelectedAgent = () => {
		submitPropertyCustomData(formSubmitObject)
			.then((res) => {
				if (res.data.success) {
					handleClose();
					setSubmitSuccessStatus('success');
					setNotificationState(true);
					setNotificationMessage('Successfully assigned selected properties');
				}
			})
			.catch((err) => {
				// setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				setFormSubmitObject(clearKeys(formSubmitObject));
			});
		setSelected([]);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const [popup, setPopup] = useState(false);

	const OffMarketTable = ({ listings, newListings }) => {
		const selectedTable = (
			rowsPerPage > 0
				? stableSort(newListings, getComparator(order, orderBy)).slice(
						page * rowsPerPage,
						page * rowsPerPage + rowsPerPage
				  )
				: stableSort(newListings, getComparator(order, orderBy))
		).map((row) => {
			const isItemSelected = isSelected(row['MLNum'] + '__' + row['HomeId']);
			const isSelectedRoute = row['MLNum'] + '__' + row['HomeId'] === selectedRoute;
			return (
				<ListingCard
					selected={isItemSelected}
					favSelected={favSelected.includes(row['HomeId'])}
					setFavSelected={setFavSelected}
					mlNum={row['MLNum']}
					status={row['Status']}
					homeId={row['HomeId']}
					address={row['Address']}
					price={row['Price']}
					assessment={row['assessment'] ? row['assessment'] : row['Assessment']}
					beds={row['TotBR']}
					bathrooms={row['TotBaths']}
					kitchens={row['NumKitchens']}
					totalArea={row['LotSzSqFt']}
					expiryDate={row['ExpiryDate']}
					knockability={row['Knockability']}
					expiry={row['ExpiryDate']}
					pic={row['Pics']}
					row={row}
					depth={row['Depth']}
					frontage={row['FrontageFeet']}
					propertyArea={row['TotFlArea']}
					onCheckClick={handleClick}
					getAddress={getAddress}
					selectedRoute={isSelectedRoute ? selectedRoute : null}
					planRoutes={planRoutes}
					openInNewTab={openInNewTab}
					AssignedAt={row['AssignedAt']}
					isTaskBoard={isTaskBoard}
					isSearchActivePage={isSearchActivePage}
				/>
			);
		});
		return <div>{selectedTable}</div>;
	};
	//Note that for ComponentToPrint, it needs to receive one single component of HTML, not an array of components.
	class ComponentToPrint extends React.Component {
		render() {
			return <OffMarketTable listings={listings} newListings={newListings} />;
		}
	}

	useEffect(() => {
		if (planRoutes) setRowsPerPage(30);
	}, [planRoutes]);
	return (
		<React.Fragment>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<div className={classes.printIconContainer}></div>
			{!isDetailsTable && selected.length > 0 && (
				<CusTableToolbarOffMarket
					isSearch={isSearch}
					title={title}
					styles={classes}
					selectedListings={selected}
					numSelected={selected.length}
					handleSelect={onClickSelect}
					handleSave={onClickSave}
					handleRoutes={onClickRoutes}
					handleDelete={onClickDelete}
					handleAssignTask={onClickAssignTask}
					handleOwnerLetter={onClickOwnerLetter}
					handleCustomOwnerLetter={onClickCustomOwnerLetter}
					handlePrint={onClickPrint}
					handlePlanMyRoutes={onClickPlanMyRoutes}
					handleFUBClick={onClickSendToFUB}
					popupClick={popupClick}
					onDeselect={() => setSelected([])}
					selectAll={selectAll}
					setSelectAll={setSelectAll}
					onSelectAllClick={handleSelectAllClick}
					editAssignedTaskVisible={editAssignedTaskVisible}
					assignMyself={assignMyself}
					sendToCRMLoading={sendToCRMLoading}
				/>
			)}

			{isSearch && (
				<React.Fragment>
					<Grid
						container
						alignItems="center"
						justify="space-between"
						className={classes.mapButton}
					>
						<Button color="secondary" onClick={() => setMapResultsOpen(true)}>
							View listings on Map
						</Button>
						<CusTableHead
							isSearch={isSearch}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							headCells={listHeads}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={listings.length}
							setOrder={setOrder}
							setOrderBy={setOrderBy}
						/>
					</Grid>
					<Dialog
						maxWidth={'md'}
						fullWidth
						open={mapResultsOpen}
						onClose={() => setMapResultsOpen(false)}
						aria-labelledby="search"
					>
						<DialogTitle id="map-search" onClose={() => setMapResultsOpen(false)}>
							{'Search Results'}
						</DialogTitle>
						<DialogContent dividers>
							<MapResults listings={listings} />
						</DialogContent>
					</Dialog>
				</React.Fragment>
			)}
			<Dialog
				open={open}
				onClose={handleClose}
				PaperProps={{ classes: { root: classes.customDialog } }}
			>
				<DialogContent className={classes.dialogContentAgents}>
					<Typography variant="h5" component="h5" className={classes.subHeading}>
						Assign To agents
					</Typography>
					<Grid item style={{ width: '150px', marginTop: '20px' }}>
						<AssignTasks
							setOwner={setOwner}
							selectAgentOptions={selectAgentOptions}
							defaultAssignAgentStatusVal={assignedInfo}
							addDataToFormObject={addDataToFormObject}
						></AssignTasks>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setFormSubmitObject(clearKeys(formSubmitObject));
							handleClose();
						}}
						color="primary"
						size="large"
					>
						CANCEL
					</Button>
					<Button
						disabled={!owner}
						onClick={() => handleSelectedAgent()}
						color="primary"
						size="large"
					>
						SAVE
					</Button>
				</DialogActions>
			</Dialog>
			{popupCustomOwner && (
				<Dialog
					open={true}
					onClose={handlePopupClose}
					PaperProps={{ classes: { root: classes.customDialog } }}
				>
					<DialogTitle>Download Customized Owner Letter</DialogTitle>
					<DialogContent className={classes.dialogContent}>
						{popupLoading ? (
							<CircularProgress className={classes.spinner} />
						) : pdfCustomOwnerUrl ? (
							<a
								href={pdfCustomOwnerUrl}
								target="_blank"
								rel="noopener noreferrer"
								download="Owner Letters"
							>
								<Button
									variant="contained"
									color="primary"
									onClick={() => setPopupCustomOwner(false)}
								>
									Download Custom PDF
								</Button>
							</a>
						) : ownerLetterError ? (
							<Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
								Sorry, no properties found
							</Typography>
						) : (
							<Grid>
								<Grid>
									<Button
										variant="contained"
										color="primary"
										className={classes.button}
										onClick={() =>
											handleCustomOwnerPdf(
												'Generate PDF',
												checkedLetterhead,
												checkedUsername
											)
										}
									>
										Generate Custom PDF
									</Button>
									{/* <Button
										variant="contained"
										color="primary"
										className={classes.button}
										onClick={() =>
											handleOwnerPdf('Generate PDF With Map', checked)
										}
									>
										Generate PDF With Map
									</Button> */}
								</Grid>
								<Grid>
									<FormControlLabel
										label="Letterhead"
										control={
											<Checkbox
												color="primary"
												checked={checkedLetterhead}
												onChange={handleChangeLetterhead}
												//inputProps={{ 'aria-label': name }}
											/>
										}
									/>
								</Grid>
								<Grid>
									<FormControlLabel
										label="First and Last name"
										control={
											<Checkbox
												color="primary"
												checked={checkedUsername}
												onChange={handleChangeUsername}
												//inputProps={{ 'aria-label': name }}
											/>
										}
									/>
								</Grid>
							</Grid>
						)}
					</DialogContent>
				</Dialog>
			)}
			<Grid className={classes.resultsContainer}>
				<OffMarketTable listings={listings} newListings={listings} />
			</Grid>
			<Table>
				<TableBody>
					<TableRow>
						<TablePagination
							className={classes.pagination}
							rowsPerPageOptions={planRoutes ? [30] : [10, 20, 30, 100]}
							colSpan={9}
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={() =>
								CusPaginationActions({
									count: rows.length,
									page,
									rowsPerPage,
									onChangePage: handleChangePage
								})
							}
						/>
					</TableRow>
				</TableBody>
			</Table>
			{popup && (
				<Dialog open={true} onClose={handlePopupClose} className={classes.dialogBox}>
					<p className={classes.printListings}> Print Listings</p>
					<p className={classes.printScaleText}>
						Set print scale to 70 to fit everything
					</p>

					<DialogContent className={classes.dialogPrintContent}>
						<label>
							<input
								className={classes.checkBox}
								type="radio"
								checked={printType === 'summary'}
								onChange={() => setPrintType('summary')}
							/>
							<span>Print Summary</span>
						</label>
						<label className={classes.popupLabel}>
							<input
								className={classes.checkBox}
								type="radio"
								disabled="disabled"
								checked={printType === 'details'}
								onChange={() => setPrintType('details')}
							/>
							<span>Print Details </span>
							<span className={classes.comingSoon}>Coming Soon</span>
						</label>
						<div className={classes.printButtons}>
							<Button className={classes.summaryButton} onClick={handlePopupClose}>
								Cancel
							</Button>
							<ReactToPrint
								onBeforeGetContent={handleBeforePrint}
								onAfterPrint={onAfterPrint}
								trigger={() => (
									<Button className={classes.summaryButton}>Print</Button>
								)}
								content={() => componentRef}
							/>
						</div>
					</DialogContent>
				</Dialog>
			)}
			{showPrint && <ComponentToPrint ref={(el) => (componentRef = el)} />}
		</React.Fragment>
	);
}

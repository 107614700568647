import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { createTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import {
	CardContent,
	Grid,
	Button,
	Checkbox,
	FormGroup,
	FormControlLabel
} from '@material-ui/core';

import Tooltip from '@material-ui/core/Tooltip';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { ErrorMsg } from '../..';

import InputAdornment from '@material-ui/core/InputAdornment';

import Slider from '@material-ui/core/Slider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { CardActions, Box, Chip } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';

import { Loading } from '../..';
import { useUserState } from '../../../hook/customerHook';
import { getAllUsersAssignedToTask, getStatusByOrganization } from '../../../api/serverRequests';
import {
	priceOptions,
	numOptions,
	frontageOptions,
	areaOptions,
	subOptions,
	sizeOptions,
	yearOptions,
	highwayOptions,
	equityBuiltOptions,
	ftnProximity,
	skytrainStationProximity,
	phoneOptions,
	directionOptions,
	bedroomOptions,
	bathroomOptions,
	kitchenOptions
} from './formOptions';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		display: 'flex',
		flexFlow: 'column nowrap',
		'& .MuiList-root': {
			overflowX: 'auto',
			scrollbarWidth: 'none',
			gap: '0.2rem'
		},
		'& .MuiList-root::-webkit-scrollbar': {
			display: 'none'
		},
		'& .MuiListItem-root': {
			padding: theme.spacing(0, 1, 0, 0),
			width: 'auto'
		},
		'& .MuiChip-root': {
			padding: theme.spacing(2, 1),
			transition: 'none'
			// margin: theme.spacing(0, 1, 1, 0)
		},
		'& .MuiChip-clickable:active': {
			boxShadow: 'none'
		}
	},
	assessmentPriceSelector: {
		borderBottom: '1px solid lightgray',
		marginBottom: '1rem'
	},
	sellingStatusFilter: {
		gap: '0.7rem',
		marginTop: '1rem',
		paddingBottom: '1.5rem',
		marginBottom: '0.5rem',
		borderBottom: '1px solid lightgray',
		width: '100%'
	},
	submit: {
		margin: theme.spacing(0),
		backgroundColor: '#004F2B',
		boxShadow: 'none'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	formControlLabel: {
		fontWeight: 'bold'
	},
	searchTypes: {
		marginBottom: theme.spacing(2),
		borderBottom: '1px solid lightgray'
	},
	searchTypesOptions: {
		marginLeft: theme.spacing(1),
		marginTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		width: '100%'
	},
	slider: {
		'& .MuiSlider-thumb': {
			width: 18,
			height: 18,
			marginTop: -7,
			backgroundColor: 'grey'
		},
		'& .MuiSlider-rail': {
			height: 4,
			backgroundColor: 'grey'
		},
		'& .MuiSlider-track': {
			height: 4,
			backgroundColor: 'grey'
		},
		'&:hover': {
			'& .MuiSlider-thumb': {
				backgroundColor: 'green'
			},
			'& .MuiSlider-track': {
				backgroundColor: 'green'
			},
			'& .MuiSlider-rail': {
				height: 4,
				backgroundColor: 'green'
			}
		},
		'& .MuiSlider-valueLabel': {
			left: 'unset'
		},
		'& .MuiSlider-mark': {
			display: 'none'
		}
	},
	separator: {
		padding: theme.spacing(2),
		userSelect: 'none',
		opacity: 0.5
	},
	content: {
		padding: theme.spacing(4),
		paddingTop: theme.spacing(2),
		paddingBottom: '0 !important',
		overflowY: 'auto',
		overflowX: 'hidden'
	},
	actions: {
		padding: theme.spacing(2, 4),
		backgroundColor: '#f8f8f8',
		borderTop: '1px solid rgba(0, 0, 0, 0.12)'
	}
}));

const theme = createTheme({
	palette: {
		primary: {
			main: '#004F2B'
		}
	}
});

function PriceSelector({ min, max, onChange, value }) {
	const defaultMin = Math.floor(max * 0.5 - max * 0.1);
	const defaultMax = Math.ceil(max * 0.5 + max * 0.1);

	function handleChange(val) {
		if (val[0] < val[1]) {
			onChange && onChange(val);
		}
	}

	const startAdornment = <InputAdornment position="start">$</InputAdornment>;
	return (
		<Grid
			container
			item
			xs={12}
			style={{ gap: '0.5rem', paddingBottom: '1.5rem', paddingTop: '2.5rem' }}
		>
			<PriceSlider value={value} min={min} max={max} onChange={(val) => handleChange(val)} />
		</Grid>
	);
}

function generateMarks() {
	const result = [];
	let index = 0;
	let scaledValue = 200_000;

	while (scaledValue <= 10_000_000) {
		result.push({ value: index, scaledValue });

		scaledValue += scaledValue < 1_000_000 ? 50_000 : 500_000;
		index += 1;
	}

	if (result && result[0]) {
		result[0]['label'] = '200K';
	}
	if (result && result[34]) {
		result[34]['label'] = '10M';
	}
	return result;
}

const priceMarkers = generateMarks();

const scale = (value) => {
	const mark = priceMarkers.find((el) => {
		return el.value === value;
	});

	if (!mark) {
		return 0;
	}
	return mark.scaledValue;
};

const unscale = (value) => {
	const mark = priceMarkers.find((el) => {
		return el.scaledValue === value;
	});

	if (!mark) {
		return 0;
	}
	return mark.value;
};

function valueLabelFormat(value) {
	const units = ['', 'K', 'M'];

	let unitIndex = 0;
	let scaledValue = value;

	while (scaledValue >= 1000 && unitIndex < units.length - 1) {
		unitIndex += 1;
		scaledValue /= 1000;
	}

	return `${scaledValue}${units[unitIndex]}`;
}

function PriceSlider({ value, min, max, onChange }) {
	const classes = useStyles();
	const [displayLabel, setDisplayLabel] = useState(value !== null ? 'on' : 'off');
	const defaultMin = Math.floor(max * 0.5 - max * 0.1);
	const defaultMax = Math.ceil(max * 0.5 + max * 0.1);
	function handleChange(e, val) {
		const newMin = scale(val[0]);
		const newMax = scale(val[1]);
		onChange([newMin, newMax]);
		setDisplayLabel('on');
	}

	return (
		<Slider
			className={classes.slider}
			defaultValue={[defaultMin, defaultMax]}
			value={value !== null ? value.map((el) => unscale(el)) : [defaultMin, defaultMax]}
			min={0}
			max={priceMarkers.length - 1}
			step={1}
			marks={priceMarkers}
			onChange={handleChange}
			valueLabelDisplay={value !== null ? 'on' : 'off'}
			aria-labelledby="range-slider"
			scale={scale}
			valueLabelFormat={(val) => (displayLabel === 'on' ? valueLabelFormat(val) : '')}
		/>
	);
}

function MinMaxTextFields({
	label,
	minLabel,
	maxLabel,
	minPlaceholder,
	maxPlaceholder,
	minProps,
	maxProps,

	value,
	min,
	max,
	type,

	disabled,

	onChange
}) {
	const classes = useStyles();

	return (
		<React.Fragment>
			<Typography style={{ color: 'black' }}>{label}</Typography>
			<Grid item xs={12}>
				<Box
					display="flex"
					flexDirection="row"
					flexWrap="nowrap"
					flexGrow={1}
					alignItems="center"
				>
					<TextField
						id="standard-multiline-flexible"
						label={minLabel}
						type={type || 'number'}
						variant="outlined"
						value={value !== null ? value[0] : ''}
						defaultValue={value !== null ? value[0] : ''}
						placeholder={minPlaceholder}
						InputLabelProps={{ shrink: true }}
						fullWidth
						disabled={disabled}
						// min={min}
						// max={value !== null ? value[1] : ''}
						InputProps={Object.assign(
							{},
							{ inputProps: { min, max: value !== null ? value[1] : '' } },
							minProps
						)}
						onChange={(e) => {
							const newMin = parseInt(e.target.value, 10);
							onChange([newMin, value ? value[1] : null]);
						}}
					/>
					<Typography className={classes.separator}>-</Typography>
					<TextField
						id="standard-multiline-flexible"
						label={maxLabel}
						type={type || 'number'}
						variant="outlined"
						value={value !== null ? value[1] : ''}
						min={value !== null ? value[0] : ''}
						max={max}
						fullWidth
						disabled={disabled}
						defaultValue={value !== null ? value[1] : ''}
						placeholder={maxPlaceholder}
						InputLabelProps={{ shrink: true }}
						InputProps={Object.assign(
							{},
							{ inputProps: { min: value !== null ? value[0] : '', max } },
							minProps
						)}
						onChange={(e) => {
							const newMax = parseInt(e.target.value, 10);
							onChange([value ? value[0] : null, newMax]);
						}}
					/>
				</Box>
			</Grid>
		</React.Fragment>
	);
}

function SelectChips({ label, options, value, onChange }) {
	const chips = options.map((option, index) => {
		const selected = value === option.value;

		return (
			<ListItem key={index}>
				<Chip
					variant={selected ? 'default' : 'outlined'}
					size="large"
					label={option.label}
					clickable={true}
					color={selected ? 'primary' : 'default'}
					onClick={() => {
						if (selected) {
							onChange && onChange(null);
						} else {
							onChange && onChange(option.value);
						}
					}}
				/>
			</ListItem>
		);
	});

	return (
		<React.Fragment>
			<Typography style={{ color: 'black' }} variant="subtitle1">
				{label}
			</Typography>
			<List style={{ display: 'flex', flexDirection: 'row' }}>{chips}</List>
		</React.Fragment>
	);
}

export default function SearchForm({
	handleSearch,
	handleRefreshSearch,
	handleClose,
	areaOptionsQuery,
	subAreaOptionsQuery,
	setRestoredFilters,
	restoredFilters,
	refreshListings
}) {
	const [userState, dispatchUser] = useUserState();

	const classes = useStyles();

	let defaultValuesEmpty = {
		// searchTypePriceDrop: false,
		// searchTypeBelowLastSold: false,
		// searchTypeBelowLastAssessment: false,
		detached: true,
		attached: false,
		apartment: false,

		nonMlsSearch: false,
		mlsSearch: true,

		minMaxPrice: null,
		minMaxAssessment: null,
		bedrooms: null,
		bathrooms: null,
		kitchens: null,

		minMaxSqft: null,
		minMaxLotSize: null,
		minMaxYear: null,

		frontage: 'any',
		highwayProximity: 'any',
		skytrainStationProximity: 'any',
		ftnProximity: 'any',
		equityBuiltOptions: 'any',
		direction: 'any',
		phoneNumber: 'any',

		tenant: false,
		underConstruction: false,
		hasFlatLot: false,
		forclosedProperties: false,
		laneAccess: false,
		cornerlotProperty: false,
		forSale: false,
		forSaleStartDate: null,
		soldStatus: false,
		soldStatusStartDate: null,
		soldStatusEndDate: null,

		expiredStatus: false,
		terminatedStatus: false,
		cancelProtectedStatus: false,
		expiredStatusStartDate: null,
		expiredStatusEndDate: null,

		includeCancelProtected: false,
		includePrivacyProtected: false,
		build: false
	};

	const defaultValues = Object.assign(
		{},
		defaultValuesEmpty,
		restoredFilters,
		userState.advFilters
	);

	const { handleSubmit, control, reset, watch, setValue } = useForm({
		defaultValues
	});

	const [errorMsg] = useState('');
	const [disabled, setDisabled] = useState(false);

	const terminatedStatus = watch('terminatedStatus');
	const expiredStatus = watch('expiredStatus');
	const cancelProtectedStatus = watch('cancelProtectedStatus');

	const handleReset = () => {
		reset(defaultValuesEmpty);
		if (restoredFilters) {
			for (const key of Object.keys(defaultValuesEmpty)) {
				if (key in restoredFilters) {
					delete restoredFilters[key];
				}
			}
		}
	};

	useEffect(() => {
		if (!terminatedStatus && !expiredStatus && !cancelProtectedStatus) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	}, [terminatedStatus, expiredStatus, cancelProtectedStatus]);

	const handleSearchSubmit = (formData) => {
		let filtersPersisted = {};

		for (const key of Object.keys(formData)) {
			if (
				(formData[key] && formData[key] !== 'any') ||
				(key === 'detached' && formData['detached'] === false) ||
				(key === 'mlsSearch' && formData['mlsSearch'] === false)
			) {
				filtersPersisted[key] = formData[key];
				if (restoredFilters) restoredFilters[key] = formData[key];
			} else if (formData[key] && (formData[key] === 'any' || formData[key] === 'null')) {
				if (restoredFilters && key in restoredFilters) delete restoredFilters[key];
			}
		}
		dispatchUser({ advFilters: filtersPersisted });
		refreshListings(filtersPersisted);
		handleClose();
	};

	return (
		<React.Fragment>
			<form
				className={classes.form}
				id="advFilters"
				onSubmit={handleSubmit(handleSearchSubmit)}
			>
				<CardContent className={classes.content}>
					{errorMsg && <ErrorMsg text={errorMsg} />}
					<Grid container justifyContent="start" alignItems="center" spacing={2}>
						{/*<Grid item xs={12} className={classes.searchTypes}>
						<Typography variant="subtitle1" className={classes.formControlLabel}>
							Search type
						</Typography>
						<FormControl
							component="fieldset"
							className={classes.searchTypesOptions}
						>
							<FormGroup>
								<Controller
									name={'searchTypePriceDrop'}
									control={control}
									render={({ onChange, value }) => {
										return (
											<FormControlLabel
												control={
													<Checkbox
														checked={value}
														color={'primary'}
														onChange={(e) => {
															onChange(e.target.checked);
														}}
													/>
												}
												label="Price drop from previous listing"
											/>
										);
									}}
								></Controller>
								<Controller
									name={'searchTypeBelowLastSold'}
									control={control}
									render={({ onChange, value }) => {
										return (
											<FormControlLabel
												control={
													<Checkbox
														checked={value}
														color={'primary'}
														onChange={(e) => {
															onChange(e.target.checked);
														}}
													/>
												}
												label="Price below last sold"
											/>
										);
									}}
								></Controller>
								<Controller
									name={'searchTypeBelowLastAssessment'}
									control={control}
									render={({ onChange, value }) => {
										return (
											<FormControlLabel
												value="test1"
												control={
													<Checkbox
														checked={value}
														color={'primary'}
														onChange={(e) => {
															onChange(e.target.checked);
														}}
													/>
												}
												label="Price below assessment"
											/>
										);
									}}
								></Controller>
							</FormGroup>
						</FormControl>
					</Grid>*/}
						<Grid item xs={12} className={classes.assessmentPriceSelector}>
							<Typography className={classes.formControlLabel}>
								Dwelling Type
							</Typography>

							<FormControl
								component="fieldset"
								className={classes.searchTypesOptions}
							>
								<FormGroup style={{ flexDirection: 'row' }}>
									<Controller
										name={'detached'}
										control={control}
										render={({ onChange, value }) => {
											return (
												<FormControlLabel
													control={
														<Checkbox
															name="detached"
															checked={value}
															color={'primary'}
															onChange={(e) => {
																onChange(e.target.checked);
															}}
														/>
													}
													label="Detached"
												/>
											);
										}}
									></Controller>
									<Controller
										name={'attached'}
										control={control}
										render={({ onChange, value }) => {
											return (
												<Tooltip title="Coming soon">
													<FormControlLabel
														control={
															<Checkbox
																name="attached"
																checked={value}
																color={'primary'}
																onChange={(e) => {
																	onChange(e.target.checked);
																}}
																disabled
															/>
														}
														label="Attached"
													/>
												</Tooltip>
											);
										}}
									></Controller>
									<Controller
										name={'apartment'}
										control={control}
										render={({ onChange, value }) => {
											return (
												<Tooltip title="Coming soon">
													<FormControlLabel
														control={
															<Checkbox
																name="apartment"
																checked={value}
																color={'primary'}
																onChange={(e) => {
																	onChange(e.target.checked);
																}}
																disabled
															/>
														}
														label="Apartment"
													/>
												</Tooltip>
											);
										}}
									></Controller>
								</FormGroup>
							</FormControl>
						</Grid>
						<Grid item xs={12} className={classes.assessmentPriceSelector}>
							<Typography className={classes.formControlLabel}>
								Non-MLS offmarket Search [beta]
							</Typography>
							<Grid item xs={12} container>
								<Grid item xs={6}>
									<FormControl
										component="fieldset"
										className={classes.searchTypesOptions}
									>
										<FormGroup>
											<Controller
												name={'mlsSearch'}
												control={control}
												render={({ onChange, value }) => {
													return (
														<FormControlLabel
															control={
																<Checkbox
																	name="mlsSearch"
																	checked={value}
																	color={'primary'}
																	onChange={(e) => {
																		onChange(e.target.checked);
																	}}
																/>
															}
															label="MLS Properties"
														/>
													);
												}}
											></Controller>
										</FormGroup>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl
										component="fieldset"
										className={classes.searchTypesOptions}
									>
										<FormGroup>
											<Controller
												name={'nonMlsSearch'}
												control={control}
												render={({ onChange, value }) => {
													return (
														<FormControlLabel
															control={
																<Checkbox
																	name="nonMlsSearch"
																	checked={value}
																	color={'primary'}
																	onChange={(e) => {
																		onChange(e.target.checked);
																	}}
																/>
															}
															label="Non-MLS Properties"
														/>
													);
												}}
											></Controller>
										</FormGroup>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12}>
							<Controller
								name={'minMaxPrice'}
								control={control}
								render={({ onChange, value }) => {
									return (
										<React.Fragment>
											<Typography className={classes.formControlLabel}>
												Price range
											</Typography>
											<PriceSelector
												onChange={(data) => {
													onChange(data);
												}}
												value={value}
												min={0}
												max={priceMarkers.length}
											/>
										</React.Fragment>
									);
								}}
							></Controller>
						</Grid>

						<Grid item xs={12} className={classes.assessmentPriceSelector}>
							<Controller
								name={'minMaxAssessment'}
								control={control}
								render={({ onChange, value }) => {
									return (
										<React.Fragment>
											<Typography className={classes.formControlLabel}>
												Assessment price range
											</Typography>
											<PriceSelector
												onChange={(data) => {
													onChange(data);
												}}
												value={value}
												min={0}
												max={priceMarkers.length}
											/>
										</React.Fragment>
									);
								}}
							></Controller>
						</Grid>
						<Grid className={classes.sellingStatusFilter}>
							<Typography variant="h5" component="h5" className={classes.subHeading}>
								Selling status
							</Typography>
							<FormControl
								component="fieldset"
								className={classes.searchTypesOptions}
							>
								<FormGroup>
									<Grid container direction="row" alignItems="center">
										<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
											<Controller
												name={'forSale'}
												control={control}
												render={({ onChange, value }) => (
													<FormControlLabel
														control={
															<Checkbox
																name="forSale"
																checked={value}
																color={'primary'}
																onChange={(e) => {
																	onChange(e.target.checked);
																}}
																size="small"
															/>
														}
														label="For Sale"
													/>
												)}
											/>
										</Grid>
										<Grid item>
											<Grid
												container
												spacing={8}
												style={{ marginTop: '0px' }}
											>
												<Grid item>
													<Controller
														name={'forSaleStartDate'}
														control={control}
														render={({ onChange, value }) => (
															<TextField
																id="forSaleStartDate"
																label="Start Date"
																type="date"
																value={value}
																onChange={(e) => {
																	onChange(e.target.value);
																}}
																InputLabelProps={{
																	shrink: true
																}}
																disabled={!watch('forSale')}
																fullWidth
															/>
														)}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									<Grid container direction="row" alignItems="center">
										<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
											<Controller
												name={'soldStatus'}
												control={control}
												render={({ onChange, value }) => (
													<FormControlLabel
														control={
															<Checkbox
																name="soldStatus"
																checked={value}
																color={'primary'}
																onChange={(e) => {
																	onChange(e.target.checked);
																}}
																size="small"
															/>
														}
														label="Closed"
													/>
												)}
											/>
										</Grid>
										<Grid item>
											<Grid
												container
												spacing={8}
												style={{ marginTop: '0px' }}
											>
												<Grid item xs={6} sm={6}>
													<Controller
														name={'soldStatusStartDate'}
														control={control}
														render={({ onChange, value }) => (
															<TextField
																id="soldStatusStartDate"
																label="Start Date"
																type="date"
																value={value}
																onChange={(e) => {
																	onChange(e.target.value);
																}}
																InputLabelProps={{
																	shrink: true
																}}
																disabled={!watch('soldStatus')}
																fullWidth
															/>
														)}
													/>
												</Grid>
												<Grid item xs={6} sm={6}>
													<Controller
														name={'soldStatusEndDate'}
														control={control}
														render={({ onChange, value }) => (
															<TextField
																id="soldStatusEndDate"
																label="End Date"
																type="date"
																value={value}
																onChange={(e) => {
																	onChange(e.target.value);
																}}
																InputLabelProps={{
																	shrink: true
																}}
																disabled={!watch('soldStatus')}
																fullWidth
															/>
														)}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>

									<Grid container direction="row" style={{ marginTop: '22px' }}>
										<Grid
											item
											container
											direction="column"
											xs={12}
											sm={4}
											md={4}
											lg={4}
											xl={4}
										>
											<Grid item>
												<Controller
													name={'terminatedStatus'}
													control={control}
													render={({ onChange, value }) => {
														return (
															<FormControlLabel
																control={
																	<Checkbox
																		name="terminatedStatus"
																		checked={value}
																		color={'primary'}
																		onChange={(e) => {
																			onChange(
																				e.target.checked
																			);
																		}}
																		size="small"
																	/>
																}
																label="Terminated"
															/>
														);
													}}
												/>
											</Grid>
											<Grid item>
												<Controller
													name={'expiredStatus'}
													control={control}
													render={({ onChange, value }) => {
														return (
															<FormControlLabel
																style={{ marginRight: 'auto' }}
																control={
																	<Checkbox
																		name="expiredStatus"
																		checked={value}
																		color={'primary'}
																		onChange={(e) => {
																			onChange(
																				e.target.checked
																			);
																		}}
																		size="small"
																	/>
																}
																label="Expired"
															/>
														);
													}}
												/>
											</Grid>
											<Grid item>
												<Controller
													name={'cancelProtectedStatus'}
													control={control}
													render={({ onChange, value }) => {
														return (
															<FormControlLabel
																control={
																	<Checkbox
																		name="cancelProtectedStatus"
																		checked={value}
																		color={'primary'}
																		onChange={(e) => {
																			onChange(
																				e.target.checked
																			);
																		}}
																		size="small"
																	/>
																}
																label="Cancel protected"
																style={{ whiteSpace: 'nowrap' }}
															/>
														);
													}}
												/>
											</Grid>
										</Grid>
										<Grid item>
											<Grid
												container
												spacing={8}
												style={{ marginTop: '0px' }}
											>
												<Grid item xs={6} sm={6}>
													<Controller
														name={'expiredStatusStartDate'}
														control={control}
														render={({ onChange, value }) => (
															<TextField
																id="expiredStatusStartDate"
																label="Start Date"
																type="date"
																value={value}
																className={classes.textField}
																onChange={(e) => {
																	onChange(e.target.value);
																}}
																InputLabelProps={{
																	shrink: true
																}}
																disabled={disabled}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={6} sm={6}>
													<Controller
														name={'expiredStatusEndDate'}
														control={control}
														render={({ onChange, value }) => (
															<TextField
																id="expiredStatusEndDate"
																label="End Date"
																type="date"
																value={value}
																className={classes.textField}
																onChange={(e) => {
																	onChange(e.target.value);
																}}
																InputLabelProps={{
																	shrink: true
																}}
																disabled={disabled}
															/>
														)}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</FormGroup>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<Typography
								variant="subtitle1"
								paragraph
								className={classes.formControlLabel}
							>
								Rooms
							</Typography>

							<Controller
								name={'bedrooms'}
								control={control}
								color="primary"
								render={({ onChange, value }) => {
									return (
										<React.Fragment>
											<SelectChips
												label={'Bedrooms'}
												options={bedroomOptions}
												value={value}
												onChange={(data) => {
													onChange(data);
												}}
											/>
										</React.Fragment>
									);
								}}
							></Controller>

							<Controller
								name={'bathrooms'}
								control={control}
								color="primary"
								render={({ onChange, value }) => {
									return (
										<React.Fragment>
											<SelectChips
												label={'Bathrooms'}
												options={bathroomOptions}
												value={value}
												onChange={(data) => {
													onChange(data);
												}}
											/>
										</React.Fragment>
									);
								}}
							></Controller>

							<Controller
								name={'kitchens'}
								control={control}
								color="primary"
								render={({ onChange, value }) => {
									return (
										<SelectChips
											label={'Kitchens'}
											options={kitchenOptions}
											value={value}
											onChange={(data) => {
												onChange(data);
											}}
										/>
									);
								}}
							></Controller>
						</Grid>
						<Grid container style={{ gap: '0.7rem' }} item xs={12} direction="column">
							<Typography variant="subtitle1" className={classes.formControlLabel}>
								Property details
							</Typography>

							<Controller
								name={'minMaxSqft'}
								control={control}
								render={({ onChange, value }) => {
									return (
										<MinMaxTextFields
											label="Total square feet"
											minLabel="min sqft"
											minPlaceholder="100"
											maxLabel="max sqft"
											maxPlaceholder="10,000"
											min={100}
											max={100_000}
											minProps={{
												endAdornment: (
													<InputAdornment position="end">
														sqft
													</InputAdornment>
												)
											}}
											value={value}
											onChange={(value) => {
												onChange(value);
											}}
										/>
									);
								}}
							></Controller>

							<Controller
								name={'minMaxLotSize'}
								control={control}
								color="primary"
								render={({ onChange, value }) => {
									return (
										<MinMaxTextFields
											label="Lot size"
											minLabel="min sqft"
											minPlaceholder="100"
											maxLabel="max sqft"
											maxPlaceholder="10,000"
											min={100}
											max={100_000}
											minProps={{
												endAdornment: (
													<InputAdornment position="end">
														sqft
													</InputAdornment>
												)
											}}
											value={value}
											onChange={(value) => {
												onChange(value);
											}}
										/>
									);
								}}
							></Controller>

							<Controller
								name={'minMaxYear'}
								control={control}
								color="primary"
								render={({ onChange, value }) => {
									const min = 1950;
									const max = new Date().getFullYear();

									return (
										<MinMaxTextFields
											label="Year built"
											minLabel="min year"
											minPlaceholder={min.toString()}
											maxLabel="max year"
											maxPlaceholder={max.toString()}
											min={min}
											max={max}
											value={value}
											onChange={(value) => {
												onChange(value);
											}}
										/>
									);
								}}
							></Controller>

							<Grid
								item
								container
								style={{
									gap: '0.7rem',
									marginTop: '1rem',
									paddingBottom: '1.5rem',
									marginBottom: '0.5rem',
									borderBottom: '1px solid lightgray'
								}}
							>
								<Grid item container xs={12} style={{ alignItems: 'center' }}>
									<Grid item xs={6}>
										<Typography>Frontage</Typography>
									</Grid>
									<Grid item xs={6}>
										<Controller
											name={'frontage'}
											control={control}
											color="primary"
											render={({ onChange, value }) => {
												return (
													<Select
														fullWidth
														value={value}
														onChange={(value) => {
															onChange(value);
														}}
														style={{
															color:
																value === 'any' ? 'gray' : 'black'
														}}
													>
														{frontageOptions.map((option) => {
															if (option.value !== 'any') {
																return (
																	<MenuItem value={option.value}>
																		{option.label}
																	</MenuItem>
																);
															} else {
																return (
																	<MenuItem
																		value="any"
																		style={{
																			color: 'gray'
																		}}
																	>
																		{option.label}
																	</MenuItem>
																);
															}
														})}
													</Select>
												);
											}}
										></Controller>
									</Grid>
								</Grid>

								<Grid item container xs={12} style={{ alignItems: 'center' }}>
									<Grid item xs={6}>
										<Typography>Highway Proximity</Typography>
									</Grid>
									<Grid item xs={6}>
										<Controller
											name={'highwayProximity'}
											control={control}
											color="primary"
											render={({ onChange, value }) => {
												return (
													<Select
														fullWidth
														value={value}
														onChange={(value) => {
															onChange(value);
														}}
														style={{
															color:
																value === 'any' ? 'gray' : 'black'
														}}
													>
														{highwayOptions.map((option) => {
															if (option.value !== 'any') {
																return (
																	<MenuItem value={option.value}>
																		{option.label}
																	</MenuItem>
																);
															} else {
																return (
																	<MenuItem
																		value="any"
																		style={{
																			color: 'gray'
																		}}
																	>
																		{option.label}
																	</MenuItem>
																);
															}
														})}
													</Select>
												);
											}}
										></Controller>
									</Grid>
								</Grid>
								<Grid item container xs={12} style={{ alignItems: 'center' }}>
									<Grid item xs={6}>
										<Typography>Direction</Typography>
									</Grid>
									<Grid item xs={6}>
										<Controller
											name={'direction'}
											control={control}
											color="primary"
											render={({ onChange, value }) => {
												return (
													<Select
														fullWidth
														value={value}
														onChange={(value) => {
															onChange(value);
														}}
														style={{
															color:
																value === 'any' ? 'gray' : 'black'
														}}
													>
														{directionOptions.map((option) => {
															if (option.value !== 'any') {
																return (
																	<MenuItem value={option.value}>
																		{option.label}
																	</MenuItem>
																);
															} else {
																return (
																	<MenuItem
																		value="any"
																		style={{
																			color: 'gray'
																		}}
																	>
																		{option.label}
																	</MenuItem>
																);
															}
														})}
													</Select>
												);
											}}
										></Controller>
									</Grid>
								</Grid>
								<Grid item container xs={12} style={{ alignItems: 'center' }}>
									<Grid item xs={6}>
										<Typography>Phone Number</Typography>
									</Grid>
									<Grid item xs={6}>
										<Controller
											name={'phoneNumber'}
											control={control}
											color="primary"
											render={({ onChange, value }) => {
												return (
													<Select
														fullWidth
														value={value}
														onChange={(value) => {
															onChange(value);
														}}
														style={{
															color:
																value === 'any' ? 'gray' : 'black'
														}}
													>
														{phoneOptions.map((option) => {
															if (option.value !== 'any') {
																return (
																	<MenuItem value={option.value}>
																		{option.label}
																	</MenuItem>
																);
															} else {
																return (
																	<MenuItem
																		value="any"
																		style={{
																			color: 'gray'
																		}}
																	>
																		{option.label}
																	</MenuItem>
																);
															}
														})}
													</Select>
												);
											}}
										></Controller>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} className={classes.assessmentPriceSelector}>
							<Typography className={classes.formControlLabel}>Occupancy</Typography>
							<Grid item xs={12} container>
								<Grid item xs={6}>
									<FormControl
										component="fieldset"
										className={classes.searchTypesOptions}
									>
										<FormGroup>
											<Controller
												name={'tenant'}
												control={control}
												render={({ onChange, value }) => {
													return (
														<FormControlLabel
															control={
																<Checkbox
																	name="occupancy"
																	checked={value}
																	color={'primary'}
																	onChange={(e) => {
																		onChange(e.target.checked);
																	}}
																/>
															}
															label="Include Tenanted"
														/>
													);
												}}
											></Controller>
										</FormGroup>
									</FormControl>
								</Grid>

								<Grid item xs={6}>
									<FormControl
										component="fieldset"
										className={classes.searchTypesOptions}
									>
										<FormGroup>
											<Controller
												name={'underConstruction'}
												control={control}
												render={({ onChange, value }) => {
													return (
														<FormControlLabel
															control={
																<Checkbox
																	name="underConstruction"
																	checked={value}
																	color={'primary'}
																	onChange={(e) => {
																		onChange(e.target.checked);
																	}}
																/>
															}
															label="Include Under Construction"
														/>
													);
												}}
											></Controller>
										</FormGroup>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Typography className={classes.formControlLabel}>
								Multiplex Zoning Homes
							</Typography>

							<FormControl
								component="fieldset"
								className={classes.searchTypesOptions}
							>
								<FormGroup>
									<Controller
										name={'build'}
										control={control}
										render={({ onChange, value }) => {
											return (
												<FormControlLabel
													control={
														<Checkbox
															name="build"
															checked={value}
															color={'primary'}
															onChange={(e) => {
																onChange(e.target.checked);
															}}
														/>
													}
													label="Likely to Build"
												/>
											);
										}}
									></Controller>
								</FormGroup>
							</FormControl>
							<Grid item container xs={12} style={{ alignItems: 'center' }}>
								<Grid item xs={6}>
									<Typography>Equity built</Typography>
								</Grid>
								<Grid item xs={6}>
									<Controller
										name={'equityBuiltOptions'}
										control={control}
										color="primary"
										render={({ onChange, value }) => {
											return (
												<Select
													fullWidth
													value={value}
													onChange={(value) => {
														onChange(value);
													}}
													style={{
														color: value === 'any' ? 'gray' : 'black'
													}}
												>
													{equityBuiltOptions.map((option) => {
														if (option.value !== 'any') {
															return (
																<MenuItem value={option.value}>
																	{option.label}
																</MenuItem>
															);
														} else {
															return (
																<MenuItem
																	value="any"
																	style={{
																		color: 'gray'
																	}}
																>
																	{option.label}
																</MenuItem>
															);
														}
													})}
												</Select>
											);
										}}
									></Controller>
								</Grid>
							</Grid>
							<Grid item container xs={12} style={{ alignItems: 'center' }}>
								<Tooltip title="Frequent-Transit Network">
									<Grid item xs={6}>
										<Typography>
											<a
												target="_blank"
												href="https://www.translink.ca/-/media/translink/documents/plans-and-projects/frequent-transit-network/frequent_transit_network_map.pdf"
											>
												FTN
											</a>{' '}
											Area Proximity
										</Typography>
									</Grid>
								</Tooltip>
								<Grid item xs={6}>
									<Controller
										name={'ftnProximity'}
										control={control}
										color="primary"
										render={({ onChange, value }) => {
											return (
												<Select
													fullWidth
													value={value}
													onChange={(value) => {
														onChange(value);
													}}
													style={{
														color: value === 'any' ? 'gray' : 'black'
													}}
												>
													{ftnProximity.map((option) => {
														if (option.value !== 'any') {
															return (
																<MenuItem value={option.value}>
																	{option.label}
																</MenuItem>
															);
														} else {
															return (
																<MenuItem
																	value="any"
																	style={{
																		color: 'gray'
																	}}
																>
																	{option.label}
																</MenuItem>
															);
														}
													})}
												</Select>
											);
										}}
									></Controller>
								</Grid>
							</Grid>
							<Grid item container xs={12} style={{ alignItems: 'center' }}>
								<Tooltip title="Transit-Oriented Development">
									<Grid item xs={6}>
										<Typography>
											<a target="_blank" href="/tod_locations_map.html">
												TOD
											</a>{' '}
											Area Proximity
										</Typography>
									</Grid>
								</Tooltip>
								<Grid item xs={6}>
									<Controller
										name={'skytrainStationProximity'}
										control={control}
										color="primary"
										render={({ onChange, value }) => {
											return (
												<Select
													fullWidth
													multiple
													value={Array.isArray(value) ? value : []}
													onChange={(event) => {
														const selectedValues = event.target.value;
														const filteredValues =
															selectedValues.filter(
																(val) => val !== 'any'
															);
														onChange(filteredValues);
													}}
													renderValue={(selected) => (
														<div
															style={{
																display: 'flex',
																flexWrap: 'wrap'
															}}
														>
															{selected.map((val) => (
																<Chip
																	key={val}
																	label={
																		skytrainStationProximity.find(
																			(option) =>
																				option.value === val
																		)?.label
																	}
																	style={{ margin: 2 }}
																/>
															))}
														</div>
													)}
													style={{
														color:
															value && value.includes('any')
																? 'gray'
																: 'black'
													}}
												>
													{skytrainStationProximity.map((option) => {
														if (option.value !== 'any') {
															return (
																<MenuItem
																	key={option.value}
																	value={option.value}
																	style={
																		option.value === 'any'
																			? { color: 'gray' }
																			: {}
																	}
																>
																	{option.label}
																</MenuItem>
															);
														} else {
															return (
																<MenuItem
																	value="any"
																	style={{
																		color: 'gray'
																	}}
																>
																	{option.label}
																</MenuItem>
															);
														}
													})}
												</Select>
											);
										}}
									></Controller>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Typography className={classes.formControlLabel}>
								Privacy Options
							</Typography>

							<FormControl
								component="fieldset"
								className={classes.searchTypesOptions}
							>
								<FormGroup>
									<Controller
										name={'includePrivacyProtected'}
										control={control}
										render={({ onChange, value }) => {
											return (
												<FormControlLabel
													control={
														<Checkbox
															name="includePrivacyProtected"
															checked={value}
															color={'primary'}
															onChange={(e) => {
																onChange(e.target.checked);
															}}
														/>
													}
													label="Include Privacy Protected"
												/>
											);
										}}
									></Controller>
									<Controller
										name={'includeCancelProtected'}
										control={control}
										render={({ onChange, value }) => {
											return (
												<FormControlLabel
													control={
														<Checkbox
															name="includeCancelProtected"
															checked={value}
															color={'primary'}
															onChange={(e) => {
																onChange(e.target.checked);
															}}
														/>
													}
													label="Include Cancel Protected"
												/>
											);
										}}
									></Controller>
								</FormGroup>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<Typography className={classes.formControlLabel}>
								Additional property info
							</Typography>

							<FormControl
								component="fieldset"
								className={classes.searchTypesOptions}
							>
								<FormGroup>
									<Controller
										name={'forclosedProperties'}
										control={control}
										render={({ onChange, value }) => {
											return (
												<FormControlLabel
													control={
														<Checkbox
															name="forclosedProperties"
															checked={value}
															color={'primary'}
															onChange={(e) => {
																onChange(e.target.checked);
															}}
														/>
													}
													label="Foreclosed"
												/>
											);
										}}
									></Controller>
								</FormGroup>
								<FormGroup>
									<Controller
										name={'lanewayAccess'}
										control={control}
										render={({ onChange, value }) => {
											return (
												<FormControlLabel
													control={
														<Checkbox
															name="lanewayAccess"
															checked={value}
															color={'primary'}
															onChange={(e) => {
																onChange(e.target.checked);
															}}
														/>
													}
													label="Lane Access"
												/>
											);
										}}
									></Controller>
								</FormGroup>
								<FormGroup>
									<Controller
										name={'cornerlotProperty'}
										control={control}
										render={({ onChange, value }) => {
											return (
												<FormControlLabel
													control={
														<Checkbox
															name="cornerlotProperty"
															checked={value}
															color={'primary'}
															onChange={(e) => {
																onChange(e.target.checked);
															}}
														/>
													}
													label="Corner Lot"
												/>
											);
										}}
									></Controller>
								</FormGroup>
								<FormGroup>
									<Controller
										name={'hasFlatLot'}
										control={control}
										render={({ onChange, value }) => {
											return (
												<FormControlLabel
													control={
														<Checkbox
															name="hasFlatLot"
															checked={value}
															color={'primary'}
															onChange={(e) => {
																onChange(e.target.checked);
															}}
														/>
													}
													label="Flat Lot"
												/>
											);
										}}
									></Controller>
								</FormGroup>
							</FormControl>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions className={classes.actions}>
					<Grid item container alignItems="center">
						<Grid item xs={6}>
							<Button
								onClick={() => {
									handleReset();
								}}
							>
								Clear All
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								// type="submit"
								onClick={handleSubmit(handleSearchSubmit)}
								id="advFilters"
								fullWidth
								size="large"
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								Apply
							</Button>
						</Grid>
					</Grid>
				</CardActions>
			</form>
		</React.Fragment>
	);
}

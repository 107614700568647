import React, { useEffect, useState } from 'react';

//Dynamically load components on request for faster page rendering
import loadable from '@loadable/component';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Typography } from '@material-ui/core';

import { BaseLayout, AreaOptionsAPI, SubAreaOptionsAPI } from '../../';
import { useUserState } from '../../../hook/customerHook';
import {
	searchListing,
	getSearchHistory,
	saveSearchHistoryAPI,
	getMyBookmarks,
	checkUserHasPermissionId
} from '../../../api/serverRequests';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { PERMS } from '../../../helper/perms';

const Snackbar = loadable(() => import('@material-ui/core/Snackbar'));
const Grid = loadable(() => import('@material-ui/core/Grid'));
const Paper = loadable(() => import('@material-ui/core/Paper'));
const CircularProgress = loadable(() => import('@material-ui/core/CircularProgress'));
const SearchHistoryDropdown = loadable(() => import('../..'), {
	resolveComponent: (components) => components.SearchHistory
});
const Lists = loadable(() => import('../..'), {
	resolveComponent: (components) => components.Lists
});
const MainForm = loadable(() => import('../..'), {
	resolveComponent: (components) => components.MainForm
});
const Search = loadable(() => import('../..'), {
	resolveComponent: (components) => components.Search
});

const Title = loadable(() => import('../..'), {
	resolveComponent: (components) => components.Title
});

const Loading = loadable(() => import('../..'), {
	resolveComponent: (components) => components.Loading
});

const useStyles = makeStyles((theme) => ({
	root: { display: 'flex', justifyContent: 'center' },
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	form: { display: 'flex', margin: theme.spacing(2), width: '100%' },
	fixedHeight: {
		height: 300
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	title: {
		display: 'flex',
		flexDirection: 'row'
	},
	dropdown: {
		display: 'flex',
		justifyContent: 'flex-end'
	}
}));

// temporary until loading routes design is finalized
const loadingDivStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	height: '100%',
	top: '40%',
	color: 'white'
};

export default function SearchPage() {
	const classes = useStyles();
	const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
	const [listings, setListings] = useState([]);
	const [selListings, setSelListings] = useState();
	const [showSearchResult, setShowSearchResult] = useState(false);
	const [userState, dispatchUser] = useUserState();
	const [currentFilters, setCurrentFilters] = useState({ area: [], subarea: [] });
	const [refreshSearch, setRefreshSearch] = useState(false);
	const [areaOptionsQuery, setAreaOptionsQuery] = useState([]);
	const [subAreaOptionsQuery, setSubAreaOptionsQuery] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [restoredFilters, setRestoredFilters] = useState(null);
	const [filter, setFilter] = useState(null);
	const [searchHistory, saveSearchHistory] = useState(null);
	const [bookmarks, saveBookmarks] = useState(null);
	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	const titles = [
		{ label: 'Image', numeric: false, id: 'Pics' },
		//	{ label: '', numeric: false, id: 'ML #' },
		{ label: 'Gem Score', numeric: true, id: 'Knockability' },
		{ label: 'Address', numeric: false, id: 'Address' },
		{ label: 'Area', numeric: false, id: 'Area' },
		{ label: 'SubArea', numeric: false, id: 'S/A' },
		{ label: 'Price', numeric: true, id: 'Price' },
		{ label: 'Assessment', numeric: true, id: 'assessment' },
		{ label: 'Total Bedrooms', numeric: true, id: 'Tot BR' },
		{ label: 'Total Bathrooms', numeric: true, id: 'Tot Baths' },
		{ label: 'Kitchens', numeric: true, id: '#Kitchens' },
		{ label: 'Total Floor Area', numeric: true, id: 'TotFlArea' },
		{ label: 'Year Built', numeric: true, id: 'Yr Blt' },
		{ label: 'Frontage (Feet)', numeric: true, id: 'Frontage - Feet' },
		{ label: 'Lot Size', numeric: true, id: 'Lot Sz (Sq.Ft.)' },
		{ label: 'Route', numeric: true, id: 'Route' },
		{ label: 'Expiry Date', numeric: true, id: 'ExpiryDate' },
		{ label: 'List Date', numeric: true, id: 'ListDate' },
		{ label: 'Sold Date', numeric: true, id: 'SoldDate' }
		//{label: 'Direction',numeric: false, id:''},
	];

	const cells = [
		//	'MLNum',
		'Knockability',
		'Address',
		'Area',
		'SA',
		'Price',
		'assessment',
		'TotBR',
		'TotBaths',
		'NumKitchens',
		'TotFlArea',
		'YrBlt',
		'FrontageFeet',
		'LotSzSqFt'
	];
	/* 	const titles = [
    { label: 'Address', numeric: false, id: 'Address' },
    { label: 'Area', numeric: false, id: 'Area' },
    { label: 'SubArea', numeric: false, id: 'S/A' },
    { label: 'Price', numeric: true, id: 'Price' },
    { label: 'Assessment', numeric: true, id: 'assessment' },
    { label: 'Total Floor Area', numeric: true, id: 'TotFlArea' },
    { label: 'Lot Size', numeric: true, id: 'LotSzSqFt' },
    { label: 'Frontage (Feet)', numeric: true, id: 'FrontageFeet' }
    //{label: 'Direction',numeric: false, id:''},
  ];

  const mainCells = ['Price', 'assessment'];

  const secondaryCells = [
    'MLNum',
    'Address',
    'Area',
    'SA',
    'TotBR',
    'TotBaths',
    'NumKitchens',
    'TotFlArea',
    'LotSzSqFt'
  ];

  const tertiaryCells = ['YrBlt', 'FrontageFeet']; */

	let allList = listings;
	let checkedListings;

	const handleSearch = (result) => {
		// debugger
		setListings(result);
		setShowSearchResult(true);
	};

	const viewPreviousSearch = (filters) => {
		let backendFilters = filters;

		if (backendFilters.advFilters) {
			backendFilters = {
				...backendFilters,
				...backendFilters.advFilters
			};
			delete backendFilters['advFilters'];
		}
		if (backendFilters.userFilters) {
			dispatchUser({ userFilters: backendFilters.userFilters });
			sessionStorage.setItem('userFilters', JSON.stringify(backendFilters.userFilters));

			backendFilters = {
				...backendFilters,
				...backendFilters.userFilters
			};
			delete backendFilters['userFilters'];
		}
		if (backendFilters.mapFilter) {
			dispatchUser({ mapFilter: backendFilters.mapFilter });
		}
		setRefreshSearch(true);
		searchListing({ user: userState.user_id, filters: backendFilters })
			.then((res) => {
				if (res.data.success) {
					const results = res.data.listings;
					setListings(results);
					dispatchUser({ offMarketSearchResults: results });
				}
				// handleSearch(res.data.listings)
				setRefreshSearch(false);
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage('Error assigning task');
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				console.log(err.message);
			});

		if (filters.advFilters) {
			if ('minAssessment' in filters.advFilters) {
				let minVal = filters.advFilters['minAssessment'];
				let maxVal = filters.advFilters['maxAssessment'];
				filters.advFilters['minMaxAssessment'] = [minVal, maxVal];
				delete filters.advFilters['maxAssessment'];
				delete filters.advFilters['minAssessment'];
			}
			if ('minPrice' in filters.advFilters) {
				let minVal = filters.advFilters['minPrice'];
				let maxVal = filters.advFilters['maxPrice'];
				filters.advFilters['minMaxPrice'] = [minVal, maxVal];
				delete filters.advFilters['minPrice'];
				delete filters.advFilters['maxPrice'];
			}
			dispatchUser({ advFilters: filters.advFilters });
		}
		setRestoredFilters(filters);
		setFilter(backendFilters);
	};

	const saveFiltersToSearchHistory = (validfilters) => {
		//don't save to search history if searching with 0 filters
		if (Object.keys(validfilters).length === 1) return;
		const item = JSON.stringify(validfilters);
		saveSearchHistoryAPI({ history: item })
			.then((res) => {
				if (res.data.success) {
					const data = res.data.history;
					const history = [data, ...searchHistory];
					saveSearchHistory(history);
				}
			})
			.catch((err) => {
				console.log(err.message);
			});
	};

	const combineFilters = (filters, saveToHistory) => {
		const mapKeys = ['lat', 'lon', 'radius'];
		const advFilters = JSON.parse(sessionStorage.getItem('advFilters'));
		const userFilters = JSON.parse(sessionStorage.getItem('userFilters'));
		const areaFilter = JSON.parse(sessionStorage.getItem('filters'));
		const routeFilters = JSON.parse(sessionStorage.getItem('routeFilters'));

		// map filters

		const circleFilter = JSON.parse(sessionStorage.getItem('circleFilter'));
		const rectFilter = JSON.parse(sessionStorage.getItem('rectFilter'));

		//do not allow user to search if area or mapFilter isn't enabled
		// if there is no "areaFilter" or no "MapFilter", but we have "filters", we check that "filters"
		if (!areaFilter && !circleFilter && !rectFilter && filters) {
			if (
				('rectFilter' in filters && Object.keys(filters.rectFilter).length === 0) ||
				('circleFilter' in filters && Object.keys(filters.circleFilter).length === 0) ||
				!('area' in filters)
			)
				return;
		}
		if (areaFilter && !filters.area && !filters.subarea) {
			filters = {
				...areaFilter
			};
		}

		setRefreshSearch(true);
		let validfilters = {};
		if (
			(!userState.circleFilter || Object.keys(userState.circleFilter).length === 0) &&
			(!userState.rectFilter || Object.keys(userState.rectFilter).length === 0)
		) {
			for (const key of Object.keys(filters)) {
				if (filters[key]) {
					if (key === 'subarea' && filters[key].length > 0) {
						validfilters[key] = [];
						filters[key].forEach((ele) => {
							validfilters[key].push(ele['value']['short']);
						});
						continue;
					}
					if (key === 'area' && filters[key].length > 0) {
						validfilters[key] = [];
						filters[key].forEach((ele) => {
							validfilters[key].push(ele['value']);
						});
						continue;
					}
				}
			}
		}
		let backendFilters = validfilters;
		let validAdvFilters = {};
		if (advFilters) {
			for (const key of Object.keys(advFilters)) {
				if (advFilters[key] && advFilters[key] !== 'any') {
					if (key.startsWith('minMax')) {
						const propertyName = key.replace('minMax', '');

						const minValue = advFilters[key][0];
						const maxValue = advFilters[key][1];
						if (minValue !== null) {
							validAdvFilters['min' + propertyName] = minValue;
						}
						if (maxValue !== null) {
							validAdvFilters['max' + propertyName] = maxValue;
						}
					} else {
						validAdvFilters[key] = advFilters[key];
					}
				}
			}
			validfilters['advFilters'] = validAdvFilters;
			backendFilters = {
				...backendFilters,
				...validAdvFilters
			};
			delete backendFilters['advFilters'];
		}

		let validUserFilters = {};
		if (userFilters) {
			for (const key of Object.keys(userFilters)) {
				if (userFilters[key] && userFilters[key] !== 'any') {
					validUserFilters[key] = userFilters[key];
				}
			}
			validfilters['userFilters'] = validUserFilters;
			backendFilters = {
				...backendFilters,
				...validUserFilters
			};
			delete backendFilters['advFilters'];
		}

		let validRouteFilters = {};
		if (routeFilters) {
			for (const key of Object.keys(routeFilters)) {
				if (routeFilters[key] && routeFilters[key] !== 'any') {
					validRouteFilters[key] = routeFilters[key];
				}
			}
			validfilters['routeFilters'] = validRouteFilters;
			backendFilters = {
				...backendFilters,
				...validRouteFilters
			};
			delete backendFilters['routeFilters'];
		}

		if (circleFilter !== null && Object.keys(circleFilter).length > 0) {
			validfilters['circleFilter'] = circleFilter;
			backendFilters['circleFilter'] = circleFilter;
		}

		if (rectFilter !== null && Object.keys(rectFilter).length > 0) {
			validfilters['rectFilter'] = rectFilter;
			backendFilters['rectFilter'] = rectFilter;
		}

		validfilters['searchHistory'] = moment();

		if (saveToHistory) {
			saveFiltersToSearchHistory(validfilters);
		}

		return backendFilters;
	};

	const refreshListings = (filters, saveToHistory = true) => {
		let backendFilters = combineFilters(filters, saveToHistory);

		searchListing({ user: userState.user_id, filters: backendFilters })
			.then((res) => {
				if (res.data.success) {
					const results = res.data.listings;
					setListings(results);
					console.log('what is listing?', results);

					// Store the search results in the session storage
					dispatchUser({ offMarketSearchResults: results });

					if (results.length === 0) {
						setShowSearchResult(true);
					}
				}
				// handleSearch(res.data.listings)
				setRefreshSearch(false);
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					setNotificationState(true);
					setNotificationMessage('Error assigning task');
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				console.log(err.message);
			});
		setFilter(backendFilters);
	};

	const handleSelect = (selectedlistings, on) => {
		if (on === false) {
			let tempListings = [];
			for (let i = 0; i < listings.length; i++) {
				for (let j = 0; j < selectedlistings.length; j++) {
					if (
						`${listings[i]['MLNum']}__${listings[i]['HomeId']}` === selectedlistings[j]
					) {
						tempListings.push(listings[i]);
					}
				}
			}

			setSelListings(tempListings);
		} else {
			setSelListings();
		}
	};

	useEffect(() => {
		localStorage.setItem('userState', JSON.stringify(userState));
		localStorage.setItem('isLoggedIn', true);
		getSearchHistory()
			.then((res) => {
				if (res.data.success) {
					const results = res.data.history;
					saveSearchHistory(results);
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				console.log(err.message);
			});

		// Load the search results from the local storage
		const storedSearchResults = sessionStorage.getItem('offMarketSearchResults');
		if (storedSearchResults) {
			setListings(JSON.parse(storedSearchResults));
		}
	}, []);

	useEffect(() => {
		getMyBookmarks()
			.then((res) => {
				if (res.data.success) {
					const results = res.data.history;
					saveBookmarks(results);
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				if (err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
					return;
				}
				console.log(err.message);
			});
	}, []);

	useEffect(() => {
		const storedSearchResults = sessionStorage.getItem('offMarketSearchResults');

		if (storedSearchResults) {
			const parsedResults = JSON.parse(storedSearchResults);
			setListings(parsedResults);

			// If parsedResults array is empty, set showSearchResult to true
			if (parsedResults.length === 0) {
				setShowSearchResult(true);
			}
		} else {
			if (userState.filters) {
				refreshListings(userState.filters, false);
			}

			if (userState.mapFilter) {
				refreshListings(userState.mapFilter, false);
			}
		}

		if (localStorage.getItem('subAreaOptions') == null) {
			SubAreaOptionsAPI(setSubAreaOptionsQuery, 'subAreaOptions');
		} else {
			setSubAreaOptionsQuery(JSON.parse(localStorage.getItem('subAreaOptions')));
		}

		if (localStorage.getItem('areaOptionsOffMarket') == null) {
			AreaOptionsAPI(
				setAreaOptionsQuery,
				'permission',
				'areaOptionsOffMarket',
				userState.user.user_id
			);
		} else {
			setAreaOptionsQuery(JSON.parse(localStorage.getItem('areaOptionsOffMarket')));
		}
	}, []);
	// permission for assigning tasks
	const [editAssignedTaskVisible, setEditAssignedTaskVisible] = useState(false);
	// permission for assign task only for myself
	const [assignMyself, setAssignMyself] = useState(false);
	const EDIT_ASSIGN_TASKS_PERM_ID = PERMS.EDIT_ASSIGN_TASKS;
	const ASSGIN_TASK_MYSELF = PERMS.ASSIGN_TASK_MYSELF;

	useEffect(() => {
		let unmounted = false;
		const checkPermission = async (permissionId, setState) => {
			try {
				const response = await checkUserHasPermissionId(
					permissionId,
					userState.user.user_id
				);
				if (response.data.success) {
					setState(true);
				}
			} catch (err) {
				if (err.response && err.response.status === 401) {
					dispatchUser({
						isLoggedIn: false
					});
				}
			}
		};

		Promise.all([
			checkPermission(EDIT_ASSIGN_TASKS_PERM_ID, setEditAssignedTaskVisible),
			checkPermission(ASSGIN_TASK_MYSELF, setAssignMyself)
		]);

		return () => {
			unmounted = true;
		};
	}, []);

	const setInitialOrderBy = () => {
		if (
			userState.routeFilters &&
			userState.routeFilters.startAddress &&
			userState.routeFilters.endAddress
		) {
			return 'Route';
		}
		return 'Knockability';
	};

	return (
		<BaseLayout>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<Modal open={isLoading}>
				<div style={loadingDivStyle}>
					<CircularProgress color="inherit" />
				</div>
			</Modal>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<Grid item xs={12}>
							<div className={classes.title}>
								<Grid item xs={10}>
									<Typography variant="h3" component="h2">
										Property Search
									</Typography>
								</Grid>
								<Grid item xs={2} className={classes.dropdown}>
									<SearchHistoryDropdown
										handleSearchHistory={viewPreviousSearch}
										saveSearchHistory={saveSearchHistory}
										mySearchHistory={searchHistory}
										saveBookmarks={saveBookmarks}
										bookmarks={bookmarks}
										currentFilters={currentFilters}
										notificationState={setNotificationState}
										notificationMessage={setNotificationMessage}
										setSubmitSuccessStatus={setSubmitSuccessStatus}
									/>
								</Grid>
							</div>
						</Grid>
						<Grid item xs={12}>
							<MainForm
								areaOptionsQuery={areaOptionsQuery}
								subAreaOptionsQuery={subAreaOptionsQuery}
								handleRefreshSearch={setRefreshSearch}
								refreshListings={refreshListings}
								setRestoredFilters={setRestoredFilters}
								restoredFilters={restoredFilters}
								setCurrentFilters={setCurrentFilters}
								currentFilters={currentFilters}
								listings={listings}
								editAssignedTaskVisible={editAssignedTaskVisible}
								assignMyself={assignMyself}
							/>
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						{refreshSearch ? (
							<Grid container className={classes.root}>
								<Grid item xs class>
									<CircularProgress color="inherit" />
								</Grid>
							</Grid>
						) : listings && listings.length > 0 ? (
							/* 							<ListCards
                isSearch={true}
                isCollection={!showSearchResult}
                listHeads={titles}
                listMainCells={mainCells}
                listSecondaryCells={secondaryCells}
                listTertiaryCells={tertiaryCells}
                listings={listings}
                handleSave={handleSave}
                initialOrderBy={'Price'}
                title={
                  showSearchResult ? 'Search Result' : 'My Recent Added Listings'
                }
              /> */
							<Lists
								isSearch={true}
								isCollection={!showSearchResult}
								listHeads={titles}
								listCells={cells}
								listings={selListings ? selListings : listings}
								setListings={setListings}
								handleSelect={handleSelect}
								initialOrderBy={setInitialOrderBy}
								setIsLoading={setIsLoading}
								filters={filter}
								// title={showSearchResult ? '' : 'Search Results'}
								editAssignedTaskVisible={editAssignedTaskVisible}
								assignMyself={assignMyself}
								combineFilters={combineFilters}
								setRefreshSearch={setRefreshSearch}
							/>
						) : (
							<Title>
								{showSearchResult
									? 'No properties found.'
									: 'Select an area to start.'}
							</Title>
						)}
					</Paper>
				</Grid>
			</Grid>
		</BaseLayout>
	);
}
